import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { Box, Grid, Stack, Avatar, Typography, Select, MenuItem } from '@mui/material';
import SmartbetAppLayout from 'src/layouts/SmartbetAppLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountOverview from 'src/components/profile/AccountOverview';
import EditProfile from 'src/components/profile/EditProfile';
import Bookmakers from 'src/components/profile/Bookmakers';
import { useStore } from 'src/store/Store';
import { ProfileService } from 'src/api/services';
import { useLocation } from 'react-router-dom';


const PROFILE_VIEWS = {
    accountOverview: (props) => <AccountOverview {...props} />,
    editProfile: (props) => <EditProfile {...props} />,
    bookmakers: (props) => <Bookmakers {...props} />,
    settings: (props) => <></>,
};

const MENU_ITEMS = [
    {
        icon: AccountCircleIcon,
        text: "Account Overview",
        tag: "accountOverview",
    },
    {
        icon: EditIcon,
        text: "Edit Profile",
        tag: "editProfile",
    },
    {
        icon: ListIcon,
        text: "Bookmakers",
        tag: "bookmakers",
    },
    // {
    //     icon: SettingsIcon,
    //     text: "Settings",
    //     tag: "settings",
    // },
];

const Sidebar = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.primary[500],
}));

const AvatarWrapper = styled(Grid)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    display: "flex",
    padding: theme.spacing(12),
}));

const ListItem = styled(Stack)(({ theme }) => ({
    cursor: "pointer",
    width: "100%",
    padding: theme.spacing(3, 4),
    color: theme.palette.neutral[400],
    borderTop: `solid 1px ${theme.palette.neutral[400]}`,
    borderBottom: `solid 1px ${theme.palette.neutral[400]}`,
    "&&& svg": {
        color: theme.palette.neutral[400],
        width: 30,
        height: 30,
    }
}));

const ActiveListItem = styled(Stack)(({ theme }) => ({
    cursor: "pointer",
    width: "100%",
    padding: theme.spacing(3, 3),
    color: theme.palette.shades[0],
    borderTop: `solid 1px ${theme.palette.shades[0]}`,
    borderBottom: `solid 1px ${theme.palette.shades[0]}`,
    borderLeft: `solid 8px ${theme.palette.secondary[500]}`,
    "&&& svg": {
        color: theme.palette.shades[0],
        width: 36,
        height: 36,
    }
}));

const MenuItemText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "1.2rem",
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    "&&& .MuiInputBase-input": {
        color: theme.palette.primary[500],
        borderColor: theme.palette.primary[800],
        backgroundColor: theme.palette.primary[50],
        fontWeight: 700,
    }
}));


export default function Profile() {
    const location = useLocation();
    const [activePage, setActivePage] = useState(location.state?.activePage || "accountOverview");
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));
    const [store, dispatch] = useStore();

    const handleLogout = () => {
        (async () => {
            await dispatch({ type: "LOGOUT" });
        })();
    }

    useEffect(() => {
        setActivePage(location.state.activePage);
    }, [location.key]);

    useEffect(() => {
        ProfileService.getProfileDetails()
            .then((response) => {
                const user = response.data.results[0];
                dispatch({
                    type: "UPDATE_PROFILE",
                    payload: {
                        user: {
                            id: user.id,
                            firstName: user.first_name,
                            lastName: user.last_name,
                            phoneNumber: user.mobile_phone,
                            providers: user.provider_preferences,
                            telegram: user.telegram_username,
                            email: user.email,
                            oddFormat: user.default_odd_format,
                            dateJoined: new Date(+user.date_joined * 1000),
                            subscription: user.active_subscription,
                            cards: user.user_cards,
                            country: user.country,
                            emailList: user.email_in_subscription_list,
                            trialUsed: user.is_trial_used_before,
                            defaultInvestAmount: user.default_investment_amount ? +user.default_investment_amount : null,
                        },
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const getSidebarItems = () => {
        let items = [];
        MENU_ITEMS.forEach((item, idx) => {
            if (item.tag === activePage) {
                items.push(
                    <ActiveListItem key={idx} direction="row" justifyContent="flex-start" alignItems="center" spacing={2} onClick={() => setActivePage(item.tag)}>
                        <item.icon />
                        <MenuItemText>{item.text}</MenuItemText>
                    </ActiveListItem>
                )
            } else {
                items.push(
                    <ListItem key={idx} direction="row" justifyContent="flex-start" alignItems="center" spacing={2} onClick={() => setActivePage(item.tag)}>
                        <item.icon />
                        <MenuItemText>{item.text}</MenuItemText>
                    </ListItem>
                )
            }
        })
        items.push(
            <ListItem key={99} direction="row" justifyContent="flex-start" alignItems="center" spacing={2} onClick={() => handleLogout()}>
                <ExitToAppIcon />
                <MenuItemText>Logout</MenuItemText>
            </ListItem>
        )
        return items;
    }

    const getActivePage = () => {
        return PROFILE_VIEWS[activePage]({ isMobile: isMobile, setActivePage: setActivePage, store:store, dispatch: dispatch });
    }

    const children = (
        <Box sx={{ flexGrow: 1, mt: 6, mr: "10%", ml: "10%" }}>
            <Grid container spacing={0}>
                {isMobile ?
                    <Stack direction="column" sx={{ width: "100%", textAlign: "center" }}>
                        <StyledSelect id="bookmakers-select" value={activePage} onChange={(event) => setActivePage(event.target.value)}>
                            {MENU_ITEMS.map((item, idx) => {
                                return <MenuItem key={idx} value={item.tag}>{item.text}</MenuItem>
                            })}
                        </StyledSelect>
                    </Stack>
                    :
                    <Sidebar item md={3} xs={12}>
                        <Stack justifyContent="center" alignItems="baseline" spacing={0}>
                            <AvatarWrapper>
                                <Avatar alt="photoURL" sx={{ width: 80, height: 80 }} />
                            </AvatarWrapper>
                            {getSidebarItems()}
                        </Stack>
                    </Sidebar>
                }

                <Grid item md={9} xs={12}>
                    {getActivePage()}
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <SmartbetAppLayout title="Profile" children={children} sx={{ overflow: "hidden" }} />
    );
}
