import api from "src/api/Api";
import urls from "src/api/Urls";


export const listBlogPosts = (params) => {
    return api.get(urls.listBlogPosts, { params: params });
}

export const getBlogPostFromSlug = (slug) => {
    return api.get(urls.getBlogPostFromSlug(slug));
}