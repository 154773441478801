import { useState, useEffect } from 'react';
import { Grid, Button, FormGroup, FormControlLabel, Checkbox, Typography, Stack, Box } from '@mui/material';
import { Wrapper, Title, SubTitle } from "src/components/profile/styles";
import { ProfileService, CommonService } from 'src/api/services';
import { useSnackbar } from 'notistack';


export default function Bookmakers(props) {
    let alreadySelectedBookmakers = props.store.user.providers ? props.store.user.providers : [];
    if (props.store.user.subscription && props.store.user.subscription.bookmaker_count > 0 && props.store.user.providers && props.store.user.providers.length > props.store.user.subscription.bookmaker_count) {
        alreadySelectedBookmakers = [];
    }
    const [selectedBookmakers, setSelectedBookmakers] = useState(alreadySelectedBookmakers);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    let BOOKMAKERS = props.store.providers ? [...props.store.providers] : [];

    useEffect(() => {
        if (!(props.store.providers)) {
            CommonService.listProviders()
                .then((response) => {
                    const allProviders = response.data.map(provider => { return { id: provider.id, label: provider.name } });
                    BOOKMAKERS = allProviders;
                    props.dispatch({
                        type: "PROVIDERS",
                        payload: {
                            providers: allProviders,
                        }
                    });
                })
        }
    }, []);

    const handleSelectAll = () => {
        setSelectedBookmakers([...BOOKMAKERS].map(bookmaker => bookmaker.id));
    }

    const handleSelectedBookmakers = (bookmaker) => {
        let copySelected = [...selectedBookmakers];
        if (selectedBookmakers.includes(bookmaker)) {
            copySelected = copySelected.filter(item => item !== bookmaker);
        } else {
            if (props.store.user.subscription && props.store.user.subscription.bookmaker_count > 0 && copySelected.length >= props.store.user.subscription.bookmaker_count) {
                enqueueSnackbar("You have reached the limit of bookmaker count on your plan", { variant: "error" });
                enqueueSnackbar("Upgrade your plan to select all available bookmakers", { variant: "info" });
            } else {
                copySelected.push(bookmaker);
            }
        }
        setSelectedBookmakers(copySelected);
    }

    const updateProviderPreferences = () => {
        if (selectedBookmakers.length === 0) {
            enqueueSnackbar("You need to select desired bookmakers first", { variant: "error" });
        } else {
            setLoading(true);
            const payload = {
                providers: [...selectedBookmakers],
            };
            ProfileService.updateProviderPreferences(payload)
                .then((response) => {
                    if (response.status === 201) {
                        enqueueSnackbar("Bookmakers have been selected successfully", { variant: "success" });
                        props.dispatch({
                            type: "UPDATE_PROFILE",
                            payload: {
                                user: {
                                    providers: response.data.providers,
                                },
                            }
                        });
                    } else { throw "update provider preferences failed" };
                })
                .catch((err) => {
                    enqueueSnackbar("Bookmakers could not be selected", { variant: "error" });
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const getColumns = () => {
        if (!props.isMobile) {
            return (
                <>
                    <Grid item xs={4}>
                        <FormGroup>
                            {BOOKMAKERS.slice(0, 8).map((bookmaker, idx) => {
                                return <FormControlLabel key={idx} control={<Checkbox checked={selectedBookmakers.includes(bookmaker.id)} onChange={() => handleSelectedBookmakers(bookmaker.id)} />} label={bookmaker.label} />
                            })}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            {BOOKMAKERS.slice(8, 16).map((bookmaker, idx) => {
                                return <FormControlLabel key={idx} control={<Checkbox checked={selectedBookmakers.includes(bookmaker.id)} onChange={() => handleSelectedBookmakers(bookmaker.id)} />} label={bookmaker.label} />
                            })}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            {BOOKMAKERS.slice(16, 22).map((bookmaker, idx) => {
                                return <FormControlLabel key={idx} control={<Checkbox checked={selectedBookmakers.includes(bookmaker.id)} onChange={() => handleSelectedBookmakers(bookmaker.id)} />} label={bookmaker.label} />
                            })}
                        </FormGroup>
                    </Grid>
                </>
            )
        } else {
            return (
                <>
                    <Grid item xs={6}>
                        <FormGroup>
                            {BOOKMAKERS.slice(0, 11).map((bookmaker, idx) => {
                                return <FormControlLabel key={idx} control={<Checkbox checked={selectedBookmakers.includes(bookmaker.id)} onChange={() => handleSelectedBookmakers(bookmaker.id)} />} label={bookmaker.label} />
                            })}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup>
                            {BOOKMAKERS.slice(11, 22).map((bookmaker, idx) => {
                                return <FormControlLabel key={idx} control={<Checkbox checked={selectedBookmakers.includes(bookmaker.id)} onChange={() => handleSelectedBookmakers(bookmaker.id)} />} label={bookmaker.label} />
                            })}
                        </FormGroup>
                    </Grid>
                </>
            )
        }
    }

    return (
        <Wrapper>
            <Title>Bookmakers</Title>
            <SubTitle>Selected Bookmakers</SubTitle>
            {props.store.user.subscription && props.store.user.subscription.bookmaker_count > 0 ? null :
                <Box sx={{ textAlign: "left" }}>
                    <Button variant="contained" color="primary" size="small" sx={{ mb: 1 }} onClick={handleSelectAll}>Select All</Button>
                </Box>
            }
            <Grid justifyContent="space-between" alignItems="stretch" container spacing={{ xs: 10, sm: 12 }} sx={{ mb: 2, width: "100%" }}>
                {getColumns()}
            </Grid>
            <Stack direction="column" spacing={2}>
                <Typography variant="p1" color="secondary">You have selected {selectedBookmakers.length} bookmakers.
                    {props.store.user.subscription && props.store.user.subscription.bookmaker_count > 0 ? `You can select up to ${props.store.user.subscription.bookmaker_count} bookmakers.` : null}</Typography>
                <Button variant="contained" color="primary" sx={{ mt: 4, width: "30%" }} disabled={loading} onClick={() => updateProviderPreferences()}>Save Selection</Button>
            </Stack>
        </Wrapper>
    );
}
