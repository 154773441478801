import { styled } from '@mui/system';
import { Typography, Stack, Box } from '@mui/material';
import "src/assets/style/landing.css";
import HowImage from "src/assets/img/landing/how/howitworks.png";


const Wrapper = styled("section")(({ theme }) => ({
    backgroundColor: theme.palette.primary[500],
    marginTop: "40px !important",
    paddingTop: "80px !important",
}));

const HowItWorksTitle = styled(Typography)(({ theme }) => ({
    width: "60%",
    color: theme.palette.shades[0],
    fontWeight: 600,
    fontSize: "2rem",
    lineHeight: "2.5rem",
    [theme.breakpoints.down("sm")]: {
        width: "80%",
    }
}));

const HowItWorksText = styled(Typography)(({ theme }) => ({
    width: "60%",
    color: theme.palette.shades[0],
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "2rem",
    [theme.breakpoints.down("sm")]: {
        width: "80%",
    }
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    zIndex: 9,
    [theme.breakpoints.down("md")]: {
        width: "100%",
        justifyContent: "center",
        marginBottom: 0,
    }
}));

const Image = styled("img")(({ theme }) => ({
    zIndex: 9,
    margin: theme.spacing(6, 0),
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "auto",
    }
}));


export default function HowItWorks() {
    return (
        <Wrapper id="how" className="container flexCenterSpaced">
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                <HowItWorksTitle>How does AlwaysWin work?</HowItWorksTitle>
                <HowItWorksText>
                    AlwaysWin scans bookies continuously and calculates arbitrage betting opportunities.
                    It suggests you the optimal stakes to achieve maximum profit, it also refreshes the data
                    automatically in real time so you never miss out an opportunity.
                </HowItWorksText>
                <ImageWrapper>
                    <Image src={HowImage} />
                </ImageWrapper>
            </Stack>
        </Wrapper>
    );
}
