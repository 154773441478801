import { styled } from '@mui/system';
import { Box, Typography, Paper } from '@mui/material';
import "src/assets/style/landing.css";
import Feature1 from "src/assets/img/landing/features/01.png";
import Feature2 from "src/assets/img/landing/features/02.png";
import Feature3 from "src/assets/img/landing/features/03.png";
import Feature4 from "src/assets/img/landing/features/04.png";
import FeatureImage from "src/assets/img/landing/features/image.png";
import FeatureBackground from "src/assets/img/landing/features/background.png";


const FEATURE_ITEMS = [
    // {
    //     source: Feature1,
    //     text: "One-Click Betting",
    // },
    {
        source: Feature2,
        text: "Arbitrage Calculator",
    },
    {
        source: Feature3,
        text: "Easy User Interface",
    },
    {
        source: Feature4,
        text: "Telegram / SMS Notification",
    },
    {
        source: Feature1,
        text: "Smart Arbitrage Bet Filtering",
    },
    {
        source: Feature2,
        text: "Add / Remove Bookies",
    },
    {
        source: Feature3,
        text: "Manually Refresh Arbitrage Opportunities",
    },
    {
        source: Feature4,
        text: "No Downloads, Access From Any Device",
    },
];


const Wrapper = styled("section")(({ theme }) => ({
    width: "100%",
    backgroundImage: `url(${FeatureBackground})`,
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat, repeat",
    },
    [theme.breakpoints.up("md")]: {
        marginTop: "50px !important",
    }
}));

const LeftSide = styled(Box)(({ theme }) => ({
    width: "50%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        order: 1,
        margin: "50px 0",
        textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
        margin: "80px 0 50px 0",
    }
}));

const RightSide = styled(Box)(({ theme }) => ({
    width: "50%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        order: 2,
        marginTop: "30px",
    }
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    zIndex: 9,
    [theme.breakpoints.down("md")]: {
        width: "100%",
        justifyContent: "center",
        marginBottom: 0,
    }
}));

const TextHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary[600],
    fontWeight: 900,
    fontSize: "2.5rem",
    lineHeight: "3rem",
    marginBottom: theme.spacing(4),
    textAlign: "left",
    width: "50%",
}));

const FeatureWrapper = styled(Paper)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
}));

const FeatureIconWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderRadius: "50%",
    padding: theme.spacing(1.6, 1),
    width: "3.2rem",
    height: "auto",
    backgroundColor: theme.palette.neutral[50],
}));

const Image = styled("img")(({ theme }) => ({
    zIndex: 9,
}));

const FeatureText = styled(Box)(({ theme }) => ({
    width: "70%",
    color: theme.palette.neutral[800],
    fontWeight: 400,
    fontSize: "0.85rem",
    lineHeight: "1.0rem",
    alignItems: "center",
    display: "inline-flex",
    textAlign: "left"
}));


export default function Features() {
    const getFeatureComponent = (idx, feature) => {
        return (
            <FeatureText key={idx}>
                <FeatureIconWrapper sx={{ mr: 3, mt: 1.5 }}>
                    <Image src={feature.source} />
                </FeatureIconWrapper>
                {feature.text}
            </FeatureText>
        )
    }

    return (
        <Wrapper id="features" className="container flexCenterSpaced">
            <LeftSide className="flexCenter">
                <FeatureWrapper>
                    <TextHeader>Features</TextHeader>
                    {FEATURE_ITEMS.map((feature, idx) => { return getFeatureComponent(idx, feature) })}
                </FeatureWrapper>
            </LeftSide>
            <RightSide>
                <ImageWrapper>
                    <Image src={FeatureImage} />
                </ImageWrapper>
            </RightSide>
        </Wrapper>
    );
}
