import { styled } from '@mui/system';
import HeroBackground from "src/assets/img/landing/heroBg.png";
import BlogEntry from "src/components/blog/BlogEntry";
import { BlogService }from "src/api/services"
import { useEffect, useState } from "react";
import BasePagination from "src/components/BasePagination";


const Wrapper = styled("section")(({ theme }) => ({
    flexDirection: "column",
    paddingTop: "80px",
    width: "100%",
    backgroundImage: `url(${HeroBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat, repeat",
    }
}));

export default function Blog() {
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    
    useEffect(() => {
        fetchBogPosts();
    }, [page]);

    const fetchBogPosts = () => {
        const params = { page: page };
        BlogService.listBlogPosts(params)
            .then((response) => {
                setTotalCount(response.data.count);
                setBlogs(response.data.results);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    return (
        <Wrapper id="blog" className="container flexCenterSpaced">
            {blogs.map(blog => <BlogEntry key={blog.id} blogPostDetails={blog}></BlogEntry>)}
            <br/>
            <BasePagination key={"basepagination"} count={Math.ceil(totalCount / 10)} page={page} onChange={handlePageChange} />
        </Wrapper>
    );
}
