import { NavLink } from 'react-router-dom';
import { styled, alpha } from '@mui/system';
import { Typography, Box } from '@mui/material';
import { LoginForm } from 'src/components/auth/login';
import { RegisterForm } from 'src/components/auth/register';
import { SubscriptionForm } from 'src/components/auth/subscription';
import { ResetPasswordForm } from 'src/components/auth/resetPassword';
import { SetResetPasswordForm } from 'src/components/auth/setResetPassword';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { useLocation, Navigate } from 'react-router-dom';


const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 560,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
    ...theme.typography.p1,
    color: theme.palette.shades[0],
    textDecoration: "none",
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
    ...theme.typography.h4,
    color: theme.palette.shades[0],
    marginTop: "-4rem",
    marginBottom: "1.5rem",
}));

const StyledContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(6, 12),
    background: alpha(theme.palette.primary[400], 0.09),
    borderRadius: "36px",
    backdropFilter: "blur(12px)",
}));

// ----------------------------------------------------------------------

export default function Auth({ type = "login" }) {
    const location = useLocation();
    let subtitle, link, navigateText, formComponent, navLink;
    if (type === "login") {
        subtitle = "Login";
        link = "/auth/register";
        navigateText = "Don't have an account yet? Register for free";
        formComponent = <LoginForm />;
    } else if (type === "register") {
        subtitle = "Register";
        link = "/auth/login";
        navigateText = "Login if you already have an account";
        formComponent = <RegisterForm planDetails={location.state} />;
    } else if (type === "subscription") {
        if (!(location.state)) {
            return <Navigate to="/surebet/surebets" />;
        }
        subtitle = "Subscription";
        formComponent = <SubscriptionForm planDetails={location.state} />;
    } else if (type === "resetPassword") {
        subtitle = "Reset Password";
        link = "/auth/login";
        navigateText = "Return back to login";
        formComponent = <ResetPasswordForm />;
    } else if (type === "setResetPassword") {
        subtitle = "Set Password";
        link = "/auth/login";
        navigateText = "Return back to login";
        formComponent = <SetResetPasswordForm />;
    }
    if (link) {
        navLink = <StyledNavLink to={link}> {navigateText} </StyledNavLink>
    } else {
        navLink = null;
    }
    return (
        <ContentStyle>
            <StyledContainer elevation={6}>
                <NavLink to="/">
                    <SvgIconStyle src="/static/logoNoBg.svg" sx={{ width: 200, height: 100, mt: -2, mb: 8 }} />
                </NavLink>
                <StyledSubtitle gutterBottom>
                    {subtitle}
                </StyledSubtitle>
                {formComponent}
                <Box sx={{ textAlign: "center", marginTop: "18px" }}>
                    {navLink}
                </Box>
            </StyledContainer>
        </ContentStyle>
    );
}
