import { useState } from 'react';
import { styled } from '@mui/system';
import { Box, Stack, AppBar, Toolbar, Button, Modal } from '@mui/material';
import { StyledNavLink } from 'src/components/landing/navigation/StyledComponents';
import AccountPopover from './AccountPopover';
import Pricing from 'src/components/landing/sections/Pricing';
import Logo from "src/assets/svg/LogoWhite";
import { useStore } from 'src/store/Store';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 52;
const APPBAR_DESKTOP = 78;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
  backgroundColor: theme.palette.primary[500],
  marginBottom: 0
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  backgroundColor: theme.palette.primary[500],
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function AppNavbar() {
  const [store, dispatch] = useStore();
  const [openPricing, setOpenPricing] = useState(false);

  return (
    <>
      <Modal
        open={openPricing}
        sx={{ overflow: "scroll" }}
        onClose={() => setOpenPricing(false)}
      >
        <Pricing landing={false} />
      </Modal>
      <StyledAppBar>
        <StyledToolbar >
          <StyledNavLink to="/surebet/surebets" sx={{ pt: 1 }}>
            <Logo width={150} height={50} />
          </StyledNavLink>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Button color="secondary" variant="contained" size="small" onClick={store.user.subscription ? () => {} : () => setOpenPricing(true)}>
              {store.user.subscription ? store.user.subscription.subscription_name : "Free Plan"}
            </Button>
            <AccountPopover />
          </Stack>
        </StyledToolbar>
      </StyledAppBar>
    </>
  );
}
