import { useState } from 'react';
import { styled } from '@mui/system';
import { Stack, Button, Paper, Typography, List, ListItem, Modal, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox } from '@mui/material';
import { Wrapper, Title, SubTitle, ProfileItemText } from "src/components/profile/styles";
import Pricing from 'src/components/landing/sections/Pricing';
import palette from 'src/theme/palette';
import { parsePhoneNumber } from 'libphonenumber-js';
import { fDateTimeSuffixFull } from "src/utils/formatTime";
import { TIMEZONES } from "src/constants";
import { useSnackbar } from 'notistack';
import { SubscriptionService, ProfileService } from 'src/api/services';


const PlanDetails = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.primary[400],
    color: theme.palette.shades[0],
    padding: theme.spacing(8),
    borderRadius: "10px",
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4),
    }
}));

const PlanDetailsTitle = styled(Typography)(({ theme }) => ({
    fontSize: "1.4rem",
    lineHeight: "1.5rem",
    fontWeight: 700,
}));

const StyledList = styled(List)(({ theme }) => ({
    listStyle: "disc",
    paddingLeft: theme.spacing(4),
    "&&& li": {
        display: "list-item",
        lineHeight: "1rem",
        fontWeight: 500,
    }
}));


export default function AccountOverview(props) {
    const [openPricing, setOpenPricing] = useState(false);
    const [loadingCancel, setLoadingCancel] = useState(false);
    const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false);
    const [inEmailList, setInEmailList] = useState(props.store.user.emailList);
    const trialEndDate = props.store.user.subscription?.trial_end ? new Date(+props.store.user.subscription.trial_end * 1000) : null;
    const isTrialActive = trialEndDate && trialEndDate > new Date() ? true : false;
    const { enqueueSnackbar } = useSnackbar();

    const formatPhoneNumber = (value) => {
        try {
            const phoneNumber = parsePhoneNumber(value, props.store.user.country);
            return phoneNumber.formatInternational();
        } catch (error) {
            return "";
        }
    };

    const cancelPlan = () => {
        setLoadingCancel(true);
        SubscriptionService.cancelSubscription(props.store.user.subscription.id)
            .then((response) => {
                if (response.status === 200) {
                    enqueueSnackbar("Your subscription has been successfully cancelled", { variant: "success" });
                    props.dispatch({
                        type: "UPDATE_PROFILE",
                        payload: {
                            user: {
                                subscription: response.data.active_subscription,
                            },
                        }
                    });
                } else { throw "subscription cancel failed" }
            })
            .catch((err) => {
                let failMessage = "Your subscription could not be cancelled";
                enqueueSnackbar(failMessage, { variant: "error" });
            })
            .finally(() => {
                setLoadingCancel(false);
                setCancelConfirmOpen(false);
            })
    }

    const handleEmailSubscription = (e) => {
        const isSubscribed = e.target.checked;
        const payload = { email: props.store.user.email, subscribe: isSubscribed };
        ProfileService.emailSubscribe(payload)
            .then((response) => {
                if (response.status === 200) {
                    setInEmailList(isSubscribed);
                    enqueueSnackbar("Your email subscription preference has been successfully updated", { variant: "success" });
                    props.dispatch({
                        type: "UPDATE_PROFILE",
                        payload: {
                            user: {
                                emailList: isSubscribed,
                            },
                        }
                    });
                } else { throw "email subscription failed" }
            })
            .catch((err) => {
                enqueueSnackbar("Your email subscription preference could not be updated", { variant: "error" });
            })
    }

    const getDateStringFull = (date) => {
        return `${fDateTimeSuffixFull(date, props.store.user.timezone)} (${TIMEZONES.getKeyByValue(props.store.user.timezone)})`;
    }

    const cancelConfirmDialog = (
        <Dialog
            open={cancelConfirmOpen}
            onClose={() => setCancelConfirmOpen(false)}
        >
            <DialogTitle>
                Cancel subscription plan
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {isTrialActive ?
                        "You are about to cancel your trial period. You won't be able to see high profit opportunities. Your card will not be charged."
                        :
                        "You are about to cancel your subscription. You can still see the high profit opportunities before your existing plan's end date. Your subscription will not be renewed"
                    }
                </DialogContentText>
                <br />
                <DialogContentText>
                    Are you sure about that?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" disabled={loadingCancel} onClick={() => setCancelConfirmOpen(false)} autoFocus>Disagree</Button>
                <Button variant="contained" color="secondary" disabled={loadingCancel} onClick={cancelPlan}>Agree</Button>
            </DialogActions>
        </Dialog>
    )

    return (
        <>
            {cancelConfirmDialog}
            <Modal
                open={openPricing}
                sx={{ overflow: "scroll" }}
                onClose={() => setOpenPricing(false)}
            >
                <Pricing landing={false} />
            </Modal>

            <Wrapper>
                <Title>Account Overview</Title>
                <SubTitle>Profile</SubTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                    <ProfileItemText>Email</ProfileItemText>
                    <ProfileItemText sx={{ color: palette.primary[800] }}>{props.store.user.email}</ProfileItemText>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                    <ProfileItemText>Phone Number</ProfileItemText>
                    <ProfileItemText sx={{ color: palette.primary[800] }}>{formatPhoneNumber(props.store.user.phoneNumber)}</ProfileItemText>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                    <ProfileItemText>Member Since</ProfileItemText>
                    <ProfileItemText sx={{ color: palette.primary[800] }}>{props.store.user.dateJoined ? getDateStringFull(props.store.user.dateJoined) : null}</ProfileItemText>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                    <ProfileItemText>Country</ProfileItemText>
                    <ProfileItemText sx={{ color: palette.primary[800] }}>{props.store.user.country}</ProfileItemText>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                    <ProfileItemText>Email Subscription</ProfileItemText>
                    <ProfileItemText><Checkbox sx={{ mt: -1, mb: -1, ml: -1.5 }} checked={inEmailList} onChange={handleEmailSubscription} /></ProfileItemText>
                </Stack>
                <Button variant="contained" color="primary" sx={{ mt: 5, mb: 10 }} onClick={() => props.setActivePage("editProfile")}>Edit Profile</Button>
                <SubTitle>Current Plan</SubTitle>
                <PlanDetails>
                    <PlanDetailsTitle>{props.store.user.subscription ? props.store.user.subscription.subscription_name : "Free"} Plan {isTrialActive ? "(Trial)" : null}</PlanDetailsTitle>
                    <StyledList>
                        {isTrialActive ? 
                        <ListItem>
                            Your trial period will end at {getDateStringFull(trialEndDate)}
                        </ListItem>
                        : null}
                        <ListItem>
                            Your plan started at {props.store.user.subscription ?
                                getDateStringFull(+props.store.user.subscription.created * 1000)
                                : props.store.user.dateJoined ? getDateStringFull(props.store.user.dateJoined) : null}
                        </ListItem>
                        {props.store.user.subscription ?
                            (props.store.user.subscription.cancelled ?
                                <ListItem>
                                    Your subscription is cancelled and will end at {getDateStringFull(+props.store.user.subscription.due_to * 1000)}
                                </ListItem>
                                :
                                <ListItem>
                                    Your subscription will be renewed at {`${getDateStringFull(+props.store.user.subscription.due_to * 1000)} for $${props.store.user.subscription.subscription_cost}`}
                                </ListItem>)
                            : null
                        }
                        {props.store.user.subscription ?
                            props.store.user.subscription.subscription_description.split(";").map((offer, idx) => {
                                return <ListItem key={idx}>{offer}</ListItem>
                            })
                            : null
                        }
                    </StyledList>
                    <Stack direction={{ xs: "column", lg: "row" }} spacing={4} sx={{ mt: 4, mb: 0 }}>
                        <Button variant="contained" color="secondary" sx={{ whiteSpace: "nowrap" }} onClick={() => setOpenPricing(true)}>
                            {props.store.user.subscription ? "Change Your Plan" : "Upgrade Your Plan"}
                        </Button>
                        {props.store.user.subscription && !props.store.user.subscription.cancelled ?
                            <Button variant="text" color="grey" sx={{ whiteSpace: "nowrap" }} onClick={() => setCancelConfirmOpen(true)}>Cancel Your Plan</Button>
                            : null
                        }
                    </Stack>
                </PlanDetails>
            </Wrapper>
        </>
    );
}
