import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { StyledTextField } from 'src/components/auth/StyledComponents';
import { ProfileService } from 'src/api/services';
import { useSnackbar } from 'notistack';


export default function SetResetPasswordForm() {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
    const { enqueueSnackbar } = useSnackbar();

    const SetResetPasswordSchema = Yup.object().shape({
        password: Yup.string().min(8, 'Too Short!').required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema: SetResetPasswordSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            const payload = {
                password: values.password,
                token: token,
            };
            ProfileService.setResetPassword(payload)
                .then((response) => {
                    if (response.status === 200) {
                        enqueueSnackbar("New password has been successfully set", { variant: "success" });
                        navigate("/auth/login", { replace: true });
                    } else { throw "set reset password failed 39d3c2c4700f1e1b45cd54c0cc3f0eea235fcb89ecb68f741"; }
                })
                .catch((err) => {
                    let failMessage = "Failed to set new password";
                    if (err.response.data?.password) {
                        failMessage = err.response.data.password[0];
                    }
                    actions.setSubmitting(false);
                    enqueueSnackbar(failMessage, { variant: "error" });
                })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <StyledTextField
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            {...getFieldProps('password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />
                    </Stack>
                    <br />

                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                        sx={{ borderRadius: "10px" }}
                    >
                        Set Password
                    </LoadingButton>
                </Form>
            </FormikProvider >
        </>
    );
}
