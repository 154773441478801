import { styled } from '@mui/system';
import { Typography, Paper } from '@mui/material';


export const Wrapper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.shades[0],
    padding: theme.spacing(20),
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(8),
    },
}));

export const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    color: theme.palette.primary[500],
    padding: theme.spacing(6, 0),
    fontSize: "2rem",
    lineHeight: "2.5rem",
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    color: theme.palette.primary[500],
    padding: theme.spacing(2, 0),
    fontSize: "1.4rem",
    lineHeight: "1.5rem",
}));

export const ProfileItemText = styled(Typography)(({ theme }) => ({
    width: "100%",
    fontWeight: 700,
    color: theme.palette.neutral[500],
    padding: theme.spacing(2, 0),
    borderBottom: `solid 1px ${theme.palette.neutral[500]}`,
    fontSize: "1rem",
    lineHeight: "1.5rem",
}));
