import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
  main: '#919EAB',
};

const NEUTRAL = {
  50: '#F7F8F9',
  100: '#EBF3FF',
  200: '#D0D5DD',
  300: '#B8C0CC',
  400: '#A0ABBB',
  500: '#64748B',
  600: '#4B5768',
  700: '#323A46',
  800: '#191D23',
  900: '#0D0F11',
  main: '#64748B',
};

const PRIMARY = {
  50: '#EBF3FF',
  100: '#D6E6FF',
  200: '#C2DAFF',
  300: '#9AC1FF',
  400: '#004FCE',
  500: '#002868',
  600: '#002157',
  700: '#00173D',
  800: '#00112B',
  900: '#000711',
  main: '#002868',
}

const SECONDARY = {
  50: '#FFF2F2',
  100: '#FEE4E4',
  200: '#FDC9C9',
  300: '#FCA1A1',
  400: '#F95151',
  500: '#E00808',
  600: '#BB0707',
  700: '#950505',
  800: '#5D0303',
  900: '#380202',
  main: '#E00808',
};

const SUCCESS = {
  50: '#ECFDF5',
  100: '#D1FAE5',
  200: '#A7F3D0',
  300: '#6EE7B7',
  400: '#34D399',
  500: '#10B981',
  600: '#059669',
  700: '#047857',
  800: '#065F46',
  900: '#064E3B',
  main: '#10B981',
};

const WARNING = {
  50: '#FFFBEB',
  100: '#FEF3C7',
  200: '#FDE68A',
  300: '#FCD34D',
  400: '#FBBF24',
  500: '#F59E0B',
  600: '#D97706',
  700: '#B45309',
  800: '#92400E',
  900: '#78350F',
  main: '#F59E0B',
};

const ERROR = {
  50: '#FEF2F2',
  100: '#FEE2E2',
  200: '#FECACA',
  300: '#FCA5A5',
  400: '#F87171',
  500: '#EF4444',
  600: '#DC2626',
  700: '#B91C1C',
  800: '#991B1B',
  900: '#7F1D1D',
  main: '#EF4444',
};

const SHADES = {
  0: '#FFFFFF',
  100: '#000000',
  main: '#000000',
};

const DARK = {
  50: '#383C41',
  100: '#353A3F',
  200: '#33383D',
  300: '#2F3338',
  400: '#2C3036',
  500: '#272C31',
  600: '#25292F',
  700: '#23272D',
  800: '#1E2329',
  900: '#12171D',
  main: '#272C31',
};

const GRADIENTS = {
  neutral: createGradient(NEUTRAL[200], NEUTRAL[800]),
  primary: createGradient(PRIMARY[200], PRIMARY[800]),
  secondary: createGradient(SECONDARY[200], SECONDARY[800]),
  success: createGradient(SUCCESS[200], SUCCESS[800]),
  warning: createGradient(WARNING[200], WARNING[800]),
  error: createGradient(ERROR[200], ERROR[800]),
  shades: createGradient(SHADES[0], SHADES[100]),
  dark: createGradient(DARK[200], DARK[800]),
};

const palette = {
  common: { black: '#000000', white: '#ffffff' },
  neutral: { ...NEUTRAL },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  shades: { ...SHADES },
  grey: { ...GREY },
  dark: { ...DARK },
  gradients: { ...GRADIENTS },
  text: { primary: GREY[900], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
};

export default palette;
