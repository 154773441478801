import api from "src/api/Api";
import urls from "src/api/Urls";


export const login = (payload) => {
    return api.post(urls.login, payload);
}

export const register = (payload) => {
    return api.post(urls.register, payload);
}

export const resetPassword = (payload) => {
    return api.post(urls.resetPassword, payload);
}

export const setResetPassword = (payload) => {
    return api.post(urls.setResetPassword, payload);
}

export const changePassword = (payload) => {
    return api.put(urls.changePassword, payload);
}

export const getProfileDetails = () => {
    return api.get(urls.getProfileDetails);
}

export const updateProviderPreferences = (payload) => {
    return api.post(urls.updateProviderPreferences, payload);
}

export const updateProfile = (id, payload) => {
    return api.put(urls.updateProfile(id), payload);
}

export const connectTelegram = (id, payload) => {
    return api.post(urls.connectTelegram, payload);
}

export const emailSubscribe = (payload) => {
    return api.post(urls.emailSubscribe, payload);
}
