import { useState, useEffect } from 'react';
import { Stack, TextField, Button, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';
import { Wrapper, Title, SubTitle, ProfileItemText } from "src/components/profile/styles";
import palette from 'src/theme/palette';
import { ProfileService } from 'src/api/services';
import { useSnackbar } from 'notistack';


const StyledTextField = styled(TextField)(({ theme }) => ({
    width: "100%",
    "& .MuiInput-root, .MuiInput-root:after": {
        fontWeight: 700,
        color: theme.palette.primary[800],
        borderBottom: `solid 1px ${theme.palette.neutral[500]}`,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        padding: theme.spacing(1, 0),
    },
    "& .MuiInput-root:hover:not(.Mui-disabled):before, .MuiInput-root:before": {
        borderBottom: "none",
    },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    "&&& svg": {
        color: theme.palette.primary[800],
    }
}));


export default function EditProfile(props) {
    const initialInvestAmount = props.store.user.defaultInvestAmount || 100;
    const [firstName, setFirstName] = useState(props.store.user.firstName);
    const [lastName, setLastName] = useState(props.store.user.lastName);
    const [investAmount, setInvestAmount] = useState(initialInvestAmount);
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [updateProfileDisabled, setUpdateProfileDisabled] = useState(true);
    const [changePasswordDisabled, setChangePasswordDisabled] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (firstName !== props.store.user.firstName || lastName !== props.store.user.lastName || investAmount !== initialInvestAmount) {
            if (firstName === "" || lastName === "") {
                setUpdateProfileDisabled(true);
            } else {
                setUpdateProfileDisabled(false);
            }
        } else {
            setUpdateProfileDisabled(true);
        }
    }, [firstName, lastName, investAmount]);

    useEffect(() => {
        if (currentPassword !== "" && newPassword !== "" && newPassword.length >= 8 ) {
            setChangePasswordDisabled(false);
        } else {
            setChangePasswordDisabled(true);
        }
    }, [currentPassword, newPassword]);

    const handleInvestAmount = (e) => {
        if (e.target.value > 99999) {
            setInvestAmount(99999);
        } else if (e.target.value <= 0) {
            setInvestAmount(1);
        } else {
            setInvestAmount(+e.target.value);
        }
    };

    const updateProfile = () => {
        const payload = {
            first_name: firstName,
            last_name: lastName,
            default_investment_amount: investAmount,
        };
        ProfileService.updateProfile(props.store.user.id, payload)
            .then((response) => {
                if (response.status === 200) {
                    enqueueSnackbar("Profile has been successfully updated", { variant: "success" });
                    props.dispatch({
                        type: "UPDATE_PROFILE",
                        payload: {
                            user: {
                                firstName: firstName,
                                lastName: lastName,
                                defaultInvestAmount: investAmount,
                            }
                        }
                    });
                } else { throw "update profile failed" }
            })
            .catch((err) => {
                enqueueSnackbar("Profile could not be updated", { variant: "error" });
            })
    }

    const changePassword = () => {
        const payload = {
            old_password: currentPassword,
            new_password1: newPassword,
            new_password2: newPassword,
        };
        ProfileService.changePassword(payload)
            .then((response) => {
                if (response.status === 200) {
                    enqueueSnackbar("Password has been successfully changed", { variant: "success" });
                    props.dispatch({
                        type: "CHANGE_PASSWORD",
                        payload: {
                            token: response.data.token,
                        }
                    });
                    setCurrentPassword("");
                    setNewPassword("");
                } else { throw "change password failed" }
            })
            .catch((err) => {
                let failMessage = "Password could not be changed";
                if (err.response.data.old_password) {
                    failMessage = err.response.data.old_password[0];
                } else if (err.response.data.non_field_errors) {
                    failMessage = err.response.data.non_field_errors[0];
                }
                enqueueSnackbar(failMessage, { variant: "error" });
            })
    }

    return (
        <Wrapper>
            <Title>Edit Profile</Title>
            <SubTitle>Profile</SubTitle>
            <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                <ProfileItemText>Email</ProfileItemText>
                <ProfileItemText sx={{ color: palette.neutral[500] }}>{props.store?.user?.email}</ProfileItemText>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                <ProfileItemText>First Name</ProfileItemText>
                <StyledTextField variant="standard" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                <ProfileItemText>Last Name</ProfileItemText>
                <StyledTextField variant="standard" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                <ProfileItemText>Invest Amount</ProfileItemText>
                <StyledTextField type="number" min={1} max={99999} variant="standard" value={investAmount} onChange={handleInvestAmount} />
            </Stack>
            <Button onClick={updateProfile} disabled={updateProfileDisabled} sx={{ mt: 3 }} variant="contained" color="primary">Update Profile</Button>
            <SubTitle sx={{ mt: 2 }}>Change Password</SubTitle>
            <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                <ProfileItemText>Current Password</ProfileItemText>
                <StyledTextField
                    type={currentPasswordVisible ? "text" : "password"}
                    variant="standard"
                    value={currentPassword}
                    placeholder="••••••••••••••••"
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <StyledIconButton edge="start" onClick={() => setCurrentPasswordVisible((prev) => !prev)}>
                                    {currentPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                </StyledIconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                <ProfileItemText>New Password</ProfileItemText>
                <StyledTextField
                    type={newPasswordVisible ? "text" : "password"}
                    variant="standard"
                    value={newPassword}
                    placeholder="••••••••••••••••"
                    onChange={(e) => setNewPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <StyledIconButton edge="start" onClick={() => setNewPasswordVisible((prev) => !prev)}>
                                    {newPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                </StyledIconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>
            <Button onClick={changePassword} disabled={changePasswordDisabled} sx={{ mt: 3 }} variant="contained" color="primary">Change Password</Button>
        </Wrapper>
    );
}
