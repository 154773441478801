import { useRef, useState } from 'react';
import { Button, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuPopover from 'src/components/MenuPopover';
import { AUTO_REFRESH_OPTIONS } from 'src/constants';


const StyledButton = styled(Button)(({ theme }) => ({
    ...theme.typography.caption,
    fontWeight: 600,
    color: theme.palette.neutral[500],
    backgroundColor: theme.palette.primary[50],
}));


export default function AutoRefreshPopover({ autoRefreshOption, setAutoRefreshOption }) {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        if (value) {
            setAutoRefreshOption(value);
        }
        setOpen(false);
    };

    return (
        <>
            <StyledButton
                disableRipple
                endIcon={<ExpandMoreIcon />}
                size="small"
                ref={anchorRef}
                onClick={handleOpen}
            >
                {AUTO_REFRESH_OPTIONS.getLabelByValue(autoRefreshOption)}
            </StyledButton>

            <MenuPopover
                open={open}
                onClose={() => handleClose()}
                anchorEl={anchorRef.current}
            >
                {[...AUTO_REFRESH_OPTIONS].map((option) => (
                    <MenuItem
                        key={option.label}
                        onClick={() => handleClose(option.value)}
                        sx={{ typography: 'p1', p: 1 }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </MenuPopover>
        </>
    );
}
