import { styled } from '@mui/system';
import { makeStyles } from "@mui/styles";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';


const useStyles = makeStyles({
    contentClass: {
        "& p, h1, h2, h3, h4, h5, h6": {
            paddingTop: "1px",
            paddingBottom: "1px",
        },
    },
});

const StyledCard = styled(Card)(({ theme }) => ({
    marginTop: "25px",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        
    }
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
    cursor: "pointer",
    height: "250px",
    [theme.breakpoints.down("sm")]: {
        height: "150px",
    }
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    maxHeight: "350px",
    [theme.breakpoints.down("sm")]: {
        maxHeight: "250px",
    }
}));

const StyledBox = styled(Box)(({ theme }) => ({
    wordWrap: "break-word",
    overflow: "hidden",
    maxHeight: "270px",
    [theme.breakpoints.down("sm")]: {
        maxHeight: "170px",
    }
}));


export default function BlogEntry({blogPostDetails}) {
    const classes = useStyles();
    let navigate = useNavigate();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const getContent = () => {
        const maxNumberOfCharachters = isSmallScreen ? 200 : 350;
        const paragraphs = blogPostDetails.content.match(/<p>[^<].*?<\/p>/g)
        let content = '';
        
        for (let i=0; i<paragraphs.length && content.length < maxNumberOfCharachters; i++)
            content += paragraphs[i];
        
        if (content.length > maxNumberOfCharachters) {
            content = content.substring(0, maxNumberOfCharachters) + "...";
        }
        
        return parse(content);
    }

    const navigateToBlogPage = () => {
        navigate("/blog/" + blogPostDetails.slug + "/");
    }

    return (
        <StyledCard>
            {blogPostDetails.image && <StyledCardMedia
                onClick={navigateToBlogPage}
                component="img"
                image={blogPostDetails.image}
            />}
            <StyledCardContent>
                <Typography style={{cursor: "pointer"}} gutterBottom variant={isSmallScreen ? "h6" : "h4"} component="div" onClick={navigateToBlogPage}>
                {blogPostDetails.title}
                </Typography>
                <StyledBox className={classes.contentClass}>
                    {getContent()}
                </StyledBox>
            </StyledCardContent>
            <CardActions>
                <Button size="small" onClick={navigateToBlogPage}>Read More</Button>
            </CardActions>
        </StyledCard>
    );
}
