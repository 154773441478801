import { createRef } from "react";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import palette from "src/theme/palette";


const useStyles = makeStyles({
    success: {
      backgroundColor: `${palette.success[600]} !important`,
    },
    error: {
      backgroundColor: `${palette.error[600]} !important`,
    },
    warning: {
      backgroundColor: `${palette.warning[600]} !important`,
    },
    info: {
      backgroundColor: `${palette.neutral[600]} !important`,
    },
  });


export default function GlobalSnackbar({ App }) {
    const notistackRef = createRef();
    const classes = useStyles();

    const onClickClose = key => () => {
        notistackRef.current.closeSnackbar(key);
    }

    return (
        <SnackbarProvider
            ref={notistackRef}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            action={(key) => (
                <IconButton component="span" onClick={onClickClose(key)} sx={{ color: palette.shades[0] }}>
                    <CloseIcon />
                </IconButton>
            )}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
            }}
        >
            <App />
        </SnackbarProvider>
    )
}
