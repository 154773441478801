import { Skeleton } from "@mui/material";
import { styled } from "@mui/system";


const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    width: "100%",
    borderRadius: "16px",
}));


export default function BaseSkeleton({ height, sx = {} }) {
    return <StyledSkeleton height={height} variant="rectangular" sx={sx} />
}
