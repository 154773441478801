// ----------------------------------------------------------------------

export default function TextField(theme) {
    return {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-input": {
                        color: theme.palette.shades[0]
                    },
                    "&&& fieldset": {
                        borderColor: theme.palette.shades[0]
                    },
                    "&&& svg": {
                        color: theme.palette.shades[0]
                    },
                    marginTop: "8px",
                }
            }
        },
    };
}
