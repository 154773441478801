import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";


const AccordionTitle = styled(Typography)(({ theme }) => ({
    ...theme.typography.p2bold,
    color: theme.palette.neutral[600],
}));

const AccordionHeader = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.primary[100],
}));


export default function BaseAccordion({ title, children }) {
    return (
        <Accordion defaultExpanded={true}>
            <AccordionHeader expandIcon={<ExpandMoreIcon />} >
                <AccordionTitle>{title}</AccordionTitle>
            </AccordionHeader>
            <AccordionDetails sx={{ pl: 0 }}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}
