import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from '@mui/system';
import { Link } from "react-scroll";
import { Button, Box } from '@mui/material';
import Sidebar from "src/components/landing/navigation/Sidebar";
import Backdrop from "src/components/landing/Backdrop";
import "src/assets/style/landing.css";
import { StyledLoginButton, StyledSignupButton, StyledNavLink, ListItem } from "./StyledComponents";
import LogoIcon from "src/assets/svg/Logo";
import BurgerIcon from "src/assets/svg/BurgerIcon";


const Wrapper = styled("nav")(({ theme }) => ({
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "0.3s ease",
    backgroundColor: theme.palette.primary[50],
}));

const NavInner = styled(Box)(({ theme }) => ({
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1220px",
    margin: "0 auto",
    padding: "0px 30px",
}));

const BurgerWrapper = styled(Button)(({ theme }) => ({
    outline: "none",
    border: "0px",
    backgroundColor: "transparent",
    height: "100%",
    padding: "0 15px",
    display: "none",
    [theme.breakpoints.down("md")]: {
        display: "block",
    }
}));

const UlWrapper = styled("ul")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        display: "none",
    }
}));


export default function Topbar() {
    const [y, setY] = useState(window.scrollY);
    const [sidebarOpen, toggleSidebar] = useState(false);
    const location = useLocation();

    useEffect(() => {
        window.addEventListener("scroll", () => setY(window.scrollY));
        return () => {
            window.removeEventListener("scroll", () => setY(window.scrollY));
        };
    }, [y]);


    return (
        <>
            <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
            <Wrapper sx={y > 100 ? { height: "60px" } : { height: "80px" }}>
                <NavInner>
                    {location.pathname === "/" ? 
                    <Link className="flexCenter" style={{ cursor: "pointer" }} to="home" smooth={true}>
                        <LogoIcon width={144} />
                    </Link>
                    :
                    <StyledNavLink to="/">
                        <LogoIcon width={144} />
                    </StyledNavLink>
                    }
                    <BurgerWrapper onClick={() => toggleSidebar(!sidebarOpen)}>
                        <BurgerIcon />
                    </BurgerWrapper>
                    {location.pathname === "/" ? 
                    <UlWrapper>
                        <ListItem>
                            <Link activeClass="active" to="home" spy={true} smooth={true} offset={-80}>
                                Home
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link activeClass="active" to="about" spy={true} smooth={true} offset={-80}>
                                About
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link activeClass="active" to="features" spy={true} smooth={true} offset={-80}>
                                Features
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link activeClass="active" to="faq" spy={true} smooth={true} offset={-80}>
                                FAQ
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link activeClass="active" to="pricing" spy={true} smooth={true} offset={-80}>
                                Pricing
                            </Link>
                        </ListItem>
                        {/* <ListItem>
                            <StyledNavLink to="/blog" offset={-80}>
                                Blog
                            </StyledNavLink>
                        </ListItem> */}
                    </UlWrapper>
                    : null
                    }
                    <UlWrapper>
                        <StyledNavLink to="/auth/login">
                            <StyledLoginButton variant="contained" size="medium" sx={y > 100 ? {height: "48px"} : {height: "54px"}}>
                                Login
                            </StyledLoginButton>
                        </StyledNavLink>
                        <StyledNavLink to="/auth/register" sx={{ ml: 2 }}>
                            <StyledSignupButton color="secondary" variant="contained" size="medium" sx={y > 100 ? {height: "48px"} : {height: "54px"}}>
                                Sign up
                            </StyledSignupButton>
                        </StyledNavLink>
                    </UlWrapper>
                </NavInner>
            </Wrapper>
        </>
    );
}
