import { styled } from '@mui/system';
import { Paper, Typography, List, ListItem, Box } from '@mui/material';
import "src/assets/style/landing.css";


const Wrapper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.shades[0],
    padding: `${theme.spacing(6, 10)} !important`,
}));

export const MainText = styled(Typography)(({ theme }) => ({
    ...theme.typography.p3,
    color: theme.palette.neutral[500],
    lineHeight: "1.5rem",
    marginBottom: theme.spacing(2),
}));

export const StyledList = styled(List)(({ theme }) => ({
    listStyle: "auto",
    marginLeft: "auto",
    "&&& li": {
        display: "list-item",
        lineHeight: "2rem !important",
        fontWeight: 500,
    }
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.primary[400],
    cursor: "pointer",
    marginBottom: "20px",
}));

export const TextInline = styled(Box)(({ theme }) => ({
    "& p": {
        display: "inline",
    }
}));


export default function Policy({ children }) {
    return (
        <Wrapper elevation={6} className="container">
            {children}
        </Wrapper>
    );
}
