import Payment from "payment";


const clearNumber = (value = "") => {
    return value.replace(/\D+/g, "");
}

export const formatCreditCardNumber = (value) => {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case "amex":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
            break;
        case "dinersclub":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
            break;
    }

    return nextValue.trim();
}

export const formatCVC = (value, cardNumber) => {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (cardNumber) {
        const issuer = Payment.fns.cardType(cardNumber);
        maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}

export const formatExpirationDate = (value) => {
    const clearValue = clearNumber(value);
    let monthPart = "";
    if (clearValue.length >= 3) {
        if (+clearValue.slice(0, 2) > 12 || clearValue.slice(0, 2) === "00") {
            monthPart = "12";
        } else { monthPart = clearValue.slice(0, 2); }
        return `${monthPart}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
}

export const formatCardData = (data) => {
    const expiryParts = data.expiry.split('/');
    return {
        card: {
            number: data.number.replaceAll(" ", ""),
            exp_month: +expiryParts[0],
            exp_year: +("20" + expiryParts[1]),
            cvc: data.cvc
        }
    }
}
