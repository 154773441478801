import { Paper, Typography, Stack } from "@mui/material";
import { styled } from "@mui/system";
import SvgIconStyle from "./SvgIconStyle";


const HeaderText = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.primary[500],
}));

const ContainerStyle = styled(Paper)(({ theme }) => ({
    margin: 8,
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1.8, 2.4),
    },
}));


export default function BaseAppSection({ headerIcon, headerText, children, extraContent }) {
    return (
        <ContainerStyle elevation={3}>
            <Stack direction="row" alignItems="center">
                <SvgIconStyle src={headerIcon} sx={{ mr: 2 }} />
                <HeaderText>{headerText}</HeaderText>
            </Stack>
            <br />
            {extraContent ? extraContent : null}
            {children}
        </ContainerStyle>
    );
}
