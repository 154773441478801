import { useRef, useState } from 'react';
import { useStore } from '../../store/Store';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, MenuItem, Avatar, IconButton, ListItemIcon } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
// components
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Account Overview',
    linkTo: '/surebet/profile',
    state: {
      activePage: 'accountOverview',
    },
    icon: AccountCircleIcon,
  },
  {
    label: 'Edit Profile',
    linkTo: '/surebet/profile',
    state: {
      activePage: 'editProfile',
    },
    icon: EditIcon,
  },
  {
    label: 'Bookmakers',
    linkTo: '/surebet/profile',
    state: {
      activePage: 'bookmakers',
    },
    icon: ListIcon,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [store, dispatch] = useStore();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    (async () => {
      await dispatch({ type: "LOGOUT" });
    })();
    navigate('/auth/login', { replace: true });
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.neutral[50], 0.72)
            }
          })
        }}
      >
        <Avatar alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={uuidv4()}
            to={option.linkTo}
            state={option.state}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'p1', p: 2 }}
          >
            <ListItemIcon sx={{ mr: -0.5 }} fontSize="small">
              <option.icon />
            </ListItemIcon>
            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 1, pt: 0.5 }}>
          <Button fullWidth onClick={handleLogout} color="secondary" variant="outlined" size="small" endIcon={<LogoutIcon />}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
