import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { StyledTextField } from 'src/components/auth/StyledComponents';
import { ProfileService } from 'src/api/services';
import { useSnackbar } from 'notistack';


export default function ResetPasswordForm() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const ResetPasswordSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ResetPasswordSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            const payload = {
                email: values.email,
            };
            ProfileService.resetPassword(payload)
                .then((response) => {
                    if (response.status === 200) {
                        enqueueSnackbar("Password reset email has been successfully sent", { variant: "success" });
                        navigate("/auth/login", { replace: true });
                    } else { throw "reset password failed"; }
                })
                .catch((err) => {
                    let failMessage = "Failed to reset password";
                    if (err.response.data?.email) {
                        failMessage = err.response.data.email[0];
                    }
                    actions.setSubmitting(false);
                    enqueueSnackbar(failMessage, { variant: "error" });
                })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <StyledTextField
                            fullWidth
                            autoComplete="email"
                            type="email"
                            label="Email"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />
                    </Stack>
                    <br />

                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                        sx={{ borderRadius: "10px" }}
                    >
                        Reset Password
                    </LoadingButton>
                </Form>
            </FormikProvider >
        </>
    );
}
