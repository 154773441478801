import { Stack, Paper, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import SvgIconStyleSpan from "src/components/SvgIconStyleSpan";
import palette from 'src/theme/palette';
import { SPORT_ICONS } from "src/constants";
import { fDate, fTimeSuffix } from "src/utils/formatTime";


const SportTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    color: theme.palette.shades[0],
}));

const LeagueTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    color: theme.palette.neutral[800],
}));

const DateTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.p1,
    color: theme.palette.neutral[700],
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xl")]: {
        whiteSpace: "normal",
    }
}));

const TeamsTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.p1bold,
    color: theme.palette.neutral[800],
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(0.5),
    }
}));

const SportPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: '16px 0 0 0',
    backgroundColor: theme.palette.primary[500],
    height: 32,
    minWidth: 120
}));

const LeaguePaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: '0 16px 0 0',
    backgroundColor: theme.palette.primary[50],
    height: 32,
    width: "-webkit-fill-available",
}));

const DetailsPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(0, 1.5),
    borderRadius: '0 0 16px 16px',
    backgroundColor: theme.palette.shades[0],
    height: 96,
    marginBottom: 16,
    display: "flex",
    alignItems: "center",
}));


export default function UpcomingGameContainer({ gameData, store }) {
    return (
        <>
            <Stack direction="row">
                <SportPaper>
                    <Stack direction="row" alignItems="center">
                        <SvgIconStyleSpan src={SPORT_ICONS[gameData.sport]} sx={{ backgroundColor: palette.shades[0], color: palette.shades[0], width: "1.1rem", height: "1.1rem", mr: 1 }} />
                        <SportTypography noWrap>{gameData.sport}</SportTypography>
                    </Stack>
                </SportPaper>
                <LeaguePaper>
                    <LeagueTypography noWrap>{gameData.country} / {gameData.league}</LeagueTypography>
                </LeaguePaper>
            </Stack>
            <DetailsPaper elevation={3}>
                <Grid container justifyContent="center" alignItems="baseline" >
                    <Grid item xs={3}>
                        <Stack direction="column" spacing={0}>
                            <DateTypography>{fDate(gameData.starts, store.user.timezone)}</DateTypography>
                            <DateTypography>{fTimeSuffix(gameData.starts, store.user.timezone)}</DateTypography>
                        </Stack>
                    </Grid>
                    <Grid item xs={9}>
                        <TeamsTypography>{gameData.home} - {gameData.away}</TeamsTypography>
                    </Grid>
                </Grid>
            </DetailsPaper>
        </>
    );
}
