import React from "react";
import { styled } from '@mui/system';
import { Box } from '@mui/material';


const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral[900],
  width: "100%",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 99,
  opacity: 0.8,
}));


export default function Backdrop({ toggleSidebar }) {
  return <Wrapper onClick={() => toggleSidebar(false)}></Wrapper>;
}
