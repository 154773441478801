export default function Accordion(theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            marginBottom: "-20px",
          }
        }
      }
    }
  };
}
