import { useState } from "react";
import { styled } from "@mui/system";
import { FormControlLabel, FormControl, RadioGroup, Radio } from "@mui/material";
import BaseAccordion from "src/components/BaseAccordion";
import { BET_DATES } from "src/constants";


const StyledRadio = styled(Radio)(({ theme }) => ({
    '& svg': {
        width: "1.1rem",
        height: "1.1rem",
    }
}));


export default function BetDateFilter({ betDate, setBetDate }) {
    
    const handleChange = (event) => {
        setBetDate(event.target.value);
    }

    const getChildren = () => {
        return (
            <>
                <FormControl>
                    <RadioGroup row  value={betDate} onChange={handleChange}>
                        {[...BET_DATES].map(date => {
                            return (
                                <FormControlLabel
                                    value={date.value}
                                    key={date.value}
                                    control={<StyledRadio sx={{ ml: -1 }} />}
                                    label={date.label}
                                    labelPlacement="start"
                                    sx={{ ml: 1 }}
                                />
                            )
                        })}
                    </RadioGroup>
                </FormControl>
            </>
        );
    }

    return (
        <BaseAccordion title="BET DATE" children={getChildren()} />
    );
}
