import { Box, Pagination } from "@mui/material";
import { styled } from "@mui/system";


const StyledPagination = styled(Pagination)(({ theme }) => ({
    "&&& button": {
        color: theme.palette.primary[500],
    },
    "&&& button.Mui-selected": {
        backgroundColor: theme.palette.primary[100],
    }
}));


export default function BasePagination({ count, page, onChange }) {
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <StyledPagination
                count={count}
                page={page}
                onChange={onChange}
                variant="outlined"
                shape="rounded"
            />
        </Box>
    )
}
