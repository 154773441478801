import { useState, useLayoutEffect } from 'react';
import { Box, Grid, Modal } from '@mui/material';
import { styled } from "@mui/system";
import SmartbetAppLayout from 'src/layouts/SmartbetAppLayout';
import AppFiltersSection from 'src/components/AppFiltersSection';
import AppUpcomingGamesSection from 'src/components/AppUpcomingGamesSection';
import AppSurebetsSection from 'src/components/AppSurebetsSection';
import Pricing from 'src/components/landing/sections/Pricing';
import { CommonService } from 'src/api/services';
import { useStore } from 'src/store/Store';


const Wrapper = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    [theme.breakpoints.up(2000)]: {
        padding: "20px 15% 20px 15%",
    },
    [theme.breakpoints.down(2000)]: {
        padding: "20px 10% 20px 10%",
    },
    [theme.breakpoints.down("xl")]: {
        padding: "20px 6% 20px 6%",
    },
}));

const UpcomingGamesWrapper = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
        display: "none",
    },
    [theme.breakpoints.down("md")]: {
        display: "block",
        width: "100%",
    },
}));


export default function MainApp() {
    const [openPricing, setOpenPricing] = useState(false);
    const [surebetFilters, setSurebetFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [store, dispatch] = useStore();

    useLayoutEffect(() => {
        CommonService.listBetTypes({ page_size: 200 })
        .then((response) => {
            const betTypes = {};
            response.data.results.forEach(betType => betTypes[betType.id] = betType.name);
            dispatch({
                type: "BET_TYPES",
                payload: {
                    betTypes: betTypes,
                }
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }, []);

    const children = (
        <>
            <Modal
                open={openPricing}
                sx={{ overflow: "scroll" }}
                onClose={() => setOpenPricing(false)}
            >
                <Pricing landing={false} />
            </Modal>
            <Wrapper>
                <Grid container spacing={1}>
                    <Grid sx={{ width: "100%" }} item lg={3} md={4} sm={12}>
                        <AppFiltersSection loading={loading} setLoading={setLoading} setSurebetFilters={setSurebetFilters} />
                    </Grid>
                    <Grid item lg={6} md={8} sm={12}>
                        <AppSurebetsSection loading={loading} setLoading={setLoading} surebetFilters={surebetFilters} openPricing={openPricing} setOpenPricing={setOpenPricing} />
                    </Grid>
                    <UpcomingGamesWrapper item lg={3} md={0} sm={12}>
                        <AppUpcomingGamesSection />
                    </UpcomingGamesWrapper>
                </Grid>
            </Wrapper>
        </>
    );

    return (
        <SmartbetAppLayout title="Surebets" children={children} />
    );
}
