import { styled } from '@mui/system';
import { Box, Typography, Grid, Stack } from '@mui/material';
import palette from 'src/theme/palette';
import Info1 from "src/assets/img/landing/about/info1.png";
import Info2 from "src/assets/img/landing/about/info2.png";
import Info3 from "src/assets/img/landing/about/info3.png";
import Info4 from "src/assets/img/landing/about/info4.png";
import "src/assets/style/landing.css";


const FAQs = [
    {
        title: "What is arbitrage betting?",
        text: "An arbitrage bet occurs when bookmakers give different odds for the outcome of a sports event. Our system scans these bets and odds around the clock and calculates situations where you can bet and win regardless of the outcome. This way, you win either way.",
        icon: Info1,
        sx: {
            backgroundColor: palette.primary[500],
            color: palette.shades[0],
        }
    },
    {
        title: "Is this legal?",
        text: "​Yes it's 100% legal. AlwaysWin’s arbitrage betting suggestions exploit the differences in the betting market and lists you where these opportunities are found.",
        icon: Info2,
        sx: {
            backgroundColor: palette.primary[300],
            color: palette.grey[900],
        }
    },
    {
        title: "How much initial investment do I need to get started?",
        text: "There is no recommended amount. You should decide on the amount yourself but it is always better to have enough funds in your bookmaker account to take advantage of arbitrage opportunities.",
        icon: Info3,
        sx: {
            backgroundColor: palette.primary[200],
            color: palette.grey[900],
        }
    },
    {
        title: "Which plan is better for me?",
        text: "It depends what you want to see and your needs. With the Essential Plan, you get access to arbitrage bets from up to 10 bookies of your choice. You can change your preferred bookies from your profile. The Platinum Plan gives you access to all arbitrage bets from all bookies available. NO LIMITS!",
        icon: Info4,
        sx: {
            backgroundColor: palette.primary[100],
            color: palette.grey[900],
        }
    },
];

const Wrapper = styled("section")(({ theme }) => ({
    backgroundColor: theme.palette.primary[500],
    marginTop: "40px !important",
    paddingTop: "80px !important",
    background: `linear-gradient(180deg, ${theme.palette.neutral[50]} 40%, ${theme.palette.shades[0]} 0%)`,
    [theme.breakpoints.down("md")]: {
        background: `linear-gradient(180deg, ${theme.palette.neutral[50]} 35%, ${theme.palette.shades[0]} 0%)`,
    },
}));

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary[500],
    fontWeight: 800,
    fontSize: "2.8rem",
    lineHeight: "4rem",
}));

const QuestionWrapper = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(3),
    height: "auto",
}));

const QuestionTitle = styled(Typography)(({ theme }) => ({
    textTransform: "uppercase",
    fontWeight: 800,
    fontSize: "1rem",
    lineHeight: "1.4rem",
}));

const QuestionText = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: "0.9rem",
    lineHeight: "1.6rem",
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderRadius: "50%",
    padding: theme.spacing(4.4),
    width: "6rem",
    height: "auto",
    backgroundColor: theme.palette.neutral[50],
}));

const Image = styled("img")(({ theme }) => ({
}));


export default function FAQ() {
    const getQuestionBox = (idx, faq) => {
        return (
            <QuestionWrapper key={idx} sx={{ flexGrow: 1, ...faq.sx }} justifyContent="center" alignItems="center" container direction="row" >
                <Grid item xs={1} sm={1}></Grid>
                <Grid item xs={2} sm={2}>
                    <IconWrapper>
                        <Image src={faq.icon} />
                    </IconWrapper>
                </Grid>
                <Grid item xs={2} sm={1}></Grid>
                <Grid item xs={7} sm={8}>
                    <Stack direction="column" justifyContent="flex-start" alignItems="baseline" spacing={2}>
                        <QuestionTitle>{faq.title}</QuestionTitle>
                        <QuestionText>{faq.text}</QuestionText>
                    </Stack>
                </Grid>
            </QuestionWrapper>
        )
    }

    return (
        <Wrapper id="faq" className="container flexCenterSpaced">
            <Grid container spacing={4}>
                <Grid item xs={12} md={5}>
                    <Title>Frequently Asked Questions</Title>
                </Grid>
                <Grid item xs={12} md={7}>
                    {FAQs.map((faq, idx) => {
                        return getQuestionBox(idx, faq)
                    })}
                </Grid>
            </Grid>
        </Wrapper>
    );
}
