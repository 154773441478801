import { useState, useEffect } from "react";
import { styled } from '@mui/system';
import { Stack, Typography, Paper, Box, FormControl, FormControlLabel, FormHelperText, RadioGroup, Radio, Grid } from '@mui/material';
import PricingTable from "src/components/landing/PricingTable";
import { BILLING_TERMS } from "src/constants";
import CheckIcon from "src/assets/svg/CheckIcon";
import BendedLine from "src/assets/svg/BendedLine";
import palette from "src/theme/palette";
import { SubscriptionService } from 'src/api/services';


const PRICES_BASE = [
    {
        price: {
            [BILLING_TERMS.ANNUALLY.value]: "$0",
            [BILLING_TERMS.MONTHLY.value]: "$0",
        },
        planId: {
            [BILLING_TERMS.ANNUALLY.value]: 0,
            [BILLING_TERMS.MONTHLY.value]: 0,
        },
        colorScheme: {
            backgroundColor: palette.shades[0],
            color: palette.primary[700],
        },
        title: "Free Plan",
        offers: [
            "Shows arbitrage bets up to 1% profit",
            "Guaranteed profits",
        ],
        buttonProps: {
            variant: "outlined",
            sx: {
                color: palette.primary[400],
                backgroundColor: palette.shades[0],
                borderRadius: "6px",
            }
        },
        buttonText: "Start Free Plan",
        onlyLanding: true,
    },
    {
        price: {
            [BILLING_TERMS.ANNUALLY.value]: "$0",
            [BILLING_TERMS.MONTHLY.value]: "$0",
        },
        planId: {
            [BILLING_TERMS.ANNUALLY.value]: 0,
            [BILLING_TERMS.MONTHLY.value]: 0,
        },
        colorScheme: {
            backgroundColor: palette.primary[400],
            color: palette.shades[0],
        },
        title: "",
        offers: [],
        buttonProps: {
            variant: "outlined",
            sx: {
                color: palette.primary[700],
                backgroundColor: palette.shades[0],
                borderRadius: "3px",
                "&:hover": {
                    backgroundColor: palette.primary[500],
                    color: palette.shades[0],
                },
            }
        },
        buttonText: "Start 15-day Trial",
        onlyLanding: false,
    },
    {
        price: {
            [BILLING_TERMS.ANNUALLY.value]: "$0",
            [BILLING_TERMS.MONTHLY.value]: "$0",
        },
        planId: {
            [BILLING_TERMS.ANNUALLY.value]: 0,
            [BILLING_TERMS.MONTHLY.value]: 0,
        },
        colorScheme: {
            backgroundColor: palette.primary[700],
            color: palette.shades[0],
        },
        title: "",
        offers: [],
        buttonProps: {
            variant: "contained",
            sx: {
                color: palette.shades[0],
                backgroundColor: palette.primary[400],
                borderRadius: "3px",
                "&:hover": {
                    backgroundColor: palette.primary[100],
                    color: palette.primary[600],
                },
                "&:focus": {
                    color: palette.shades[0],
                    backgroundColor: palette.primary[400],
                },
            }
        },
        buttonText: "Start 15-day Trial",
        onlyLanding: false,
    },
];

const Wrapper = styled("section")(({ theme }) => ({
    backgroundColor: theme.palette.primary[100],
    marginTop: "80px !important",
    padding: theme.spacing(6),
}));

const Header = styled(Typography)(({ theme }) => ({
    fontWeight: 800,
    fontSize: "2rem",
    lineHeight: "2.2rem",
    paddingTop: theme.spacing(8),
}));

const FeaturesText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary[700],
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "1.25rem",
    alignItems: "center",
    display: "inline-flex",
    margin: theme.spacing(8, 0),
}));

const BillingPlanWrapper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.primary[200],
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: "75%",
    [theme.breakpoints.down("md")]: {
        maxWidth: "90%",
    },
}));

const BillingPlanHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary[700],
    fontWeight: 800,
    fontSize: "1.4rem",
    lineHeight: "2rem",
    marginBottom: theme.spacing(4),
}));

const BillingPlanText = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(0),
    },
    "& .MuiFormControlLabel-label": {
        color: theme.palette.neutral[600],
        fontWeight: 600,
        fontSize: "0.85rem",
        lineHeight: "1.4rem",
        marginRight: theme.spacing(8),
        [theme.breakpoints.down("md")]: {
            marginRight: theme.spacing(4),
        },
        [theme.breakpoints.down("sm")]: {
            marginRight: theme.spacing(0),
        },
    }
}));

const BillingPlanHelperWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: "-40px",
    left: "40px",
    transform: "rotate(-20deg)",
    [theme.breakpoints.down("md")]: {
        left: "-20px",
    },
    [theme.breakpoints.down("sm")]: {
        left: "-20px",
        top: "-34px",
        transform: "rotate(-1deg)",
    },
}));

const BillingPlanHelperText = styled(FormHelperText)(({ theme }) => ({
    color: theme.palette.neutral[600],
    fontFamily: "'Great Vibes', cursive",
    fontWeight: 600,
    fontSize: "1.3rem",
    lineHeight: "1rem",
}));


export default function Pricing({ landing = true }) {
    const [billingTerm, setBillingTerm] = useState(BILLING_TERMS.MONTHLY.value);
    const [plans, setPlans] = useState(PRICES_BASE);

    useEffect(() => {
      SubscriptionService.listPlans()
        .then((response) => {
            let copyPlans = [...plans];
            response.data.results.forEach((plan, idx) => {
                const planStructure = {...copyPlans[idx+1]};
                plan.costs.forEach(cost => {
                    planStructure.price[cost.recurrence_unit] = `$${(+cost.cost).toFixed(0)}`;
                    planStructure.planId[cost.recurrence_unit] = cost.id;
                });
                planStructure.offers = plan.description.split(";");
                planStructure.title = plan.name;
                copyPlans[idx+1] = planStructure;
            });
            setPlans(copyPlans);
        })
        .catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <Wrapper id="pricing" className="container">
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                <Header color="primary">Earn a Profit Every Month, virtually</Header>
                <Header color="secondary">Risk Free!</Header>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={{ xs: 2, md: 8 }}>
                <FeaturesText><CheckIcon />Free 15-day trial</FeaturesText>
                <FeaturesText><CheckIcon />Unlimited Bookies</FeaturesText>
                <FeaturesText><CheckIcon />Cancel Anytime</FeaturesText>
            </Stack>
            <BillingPlanWrapper>
                <BillingPlanHeader>Select Billing Plan</BillingPlanHeader>
                <FormControl>
                    <RadioGroup row name="billingPlan" value={billingTerm} onChange={(event) => setBillingTerm(event.target.value)}>
                        {[...BILLING_TERMS].map((term, idx) => {
                            return (
                                <Stack direction="column">
                                    {term.value === BILLING_TERMS.ANNUALLY.value ?
                                        <BillingPlanHelperWrapper>
                                            <BillingPlanHelperText>get huge discount</BillingPlanHelperText>
                                            <BendedLine />
                                        </BillingPlanHelperWrapper>
                                        : null
                                    }
                                    <BillingPlanText key={idx} value={term.value} control={<Radio />} label={`Billed ${term.label}`} />
                                </Stack>
                            )
                        })}
                    </RadioGroup>
                </FormControl>
            </BillingPlanWrapper>
            <Grid direction="row" container justifyContent="space-around" alignItems="stretch" rowSpacing={{ xs: 2, md: 4 }}>
                {plans.map((pricing, idx) => {
                    if (landing || !pricing.onlyLanding) {
                        const billingTermLabel = BILLING_TERMS.getLabelByValue(billingTerm);
                        return (
                            <PricingTable key={idx} {...{ ...pricing, price: pricing.price[billingTerm], planId: pricing.planId[billingTerm], billingTerm: billingTermLabel }} />
                        )
                    }
                })}
            </Grid>
        </Wrapper>
    );
}
