import Slider from "react-slick";
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import "src/assets/style/landing.css";
import BookieLogo01 from "src/assets/img/landing/bookies/bookie1.png"
import BookieLogo02 from "src/assets/img/landing/bookies/bookie2.png";
import BookieLogo03 from "src/assets/img/landing/bookies/bookie3.png";
import BookieLogo04 from "src/assets/img/landing/bookies/bookie4.png";
import BookieLogo05 from "src/assets/img/landing/bookies/bookie5.png";
import BookieLogo06 from "src/assets/img/landing/bookies/bookie6.png";
import BookieLogo07 from "src/assets/img/landing/bookies/bookie7.png";
import BookieLogo08 from "src/assets/img/landing/bookies/bookie8.png";
import BookieLogo09 from "src/assets/img/landing/bookies/bookie9.png";
import BookieLogo10 from "src/assets/img/landing/bookies/bookie10.png";
import BookieLogo11 from "src/assets/img/landing/bookies/bookie11.png";
import BookieLogo12 from "src/assets/img/landing/bookies/bookie12.png";
import BookieLogo13 from "src/assets/img/landing/bookies/bookie13.png";
import BookieLogo14 from "src/assets/img/landing/bookies/bookie14.png";
import BookieLogo15 from "src/assets/img/landing/bookies/bookie15.png";
import BookieLogo16 from "src/assets/img/landing/bookies/bookie16.png";


const Wrapper = styled("section")(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.shades[0],
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
    cursor: "pointer",
    "&:focus-visible": {
        outline: "none",
        border: "0px",
    }
}));

const Image = styled("img")(({ theme }) => ({
    width: "150px",
    padding: theme.spacing(3),
    borderRadius: "50%",
    [theme.breakpoints.down("lg")]: {
        width: "120px",
        padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
        width: "110px",
        padding: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
        width: "85px",
        padding: theme.spacing(1),
    },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary[500],
    fontWeight: 800,
    fontSize: "2.75rem",
    lineHeight: "4rem",
    textAlign: "center",
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
        fontSize: "2.2rem",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.75rem",
    },
    [theme.breakpoints.down("sx")]: {
        fontSize: "1.5rem",
    },
}));

const BOOKIES = [
    BookieLogo01,
    BookieLogo02,
    BookieLogo03,
    BookieLogo04,
    BookieLogo05,
    BookieLogo06,
    BookieLogo07,
    BookieLogo08,
    BookieLogo09,
    BookieLogo10,
    BookieLogo11,
    BookieLogo12,
    BookieLogo13,
    BookieLogo14,
    BookieLogo15,
    BookieLogo16,
]


export default function BookieSlider() {
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
        ]
    };
    const getLogo = (idx, bookie) => {
        return (
            <LogoWrapper key={idx} className="flexCenter">
                <Image src={bookie} />
            </LogoWrapper>
        )
    }
    return (
        <Wrapper className="container">
            <HeaderText>Integration with the Most Popular Bookies</HeaderText>
            <Slider {...settings}>
                {BOOKIES.map((bookie, idx) => {
                    return getLogo(idx, bookie);
                })}
            </Slider>
        </Wrapper>
    );
}
