import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Typography, Stack, Box, Button, Grid } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Link } from "react-scroll";
import "src/assets/style/landing.css";
import SurebetContainer from "src/components/surebet/SurebetContainer";
import { CommonService } from 'src/api/services';
import { TIMEZONES, ODD_FORMATS } from 'src/constants';
import { decimalToAmerican } from "src/utils/Util";


const DUMMY_STORE = {
    user: {
        timezone: TIMEZONES.EST.value,
        oddFormat: ODD_FORMATS.US.value,
        defaultInvestAmount: 100,
    }
};

const Wrapper = styled("section")(({ theme }) => ({
    backgroundColor: theme.palette.neutral[50],
}));

const BetsContainer = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(8, 0),
}));

const StartPlanBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
        alignItems: "stretch",
        padding: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
        alignItems: "stretch",
        padding: theme.spacing(0.5),
    },
}));

const PlanNameText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary[600],
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    marginBottom: theme.spacing(2),
    textAlign: "center",
}));


export default function SampleSurebets() {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [games, setGames] = useState([]);
    
    const getBetOptionLabel = (betType, fixture, home = true) => {
        if (betType && fixture) {
            if (fixture.over || fixture.under) {
                return `${fixture.over ? "over" : "under"} (${fixture.line})`;
            }
            else if (fixture.line) {
                return `${home ? "home" : "away"} (${fixture.line >= 0 ? "+" : ""}${fixture.line})`;
            } else {
                return home ? "home" : "away";
            }
        }
        return "";
    }

    const formatGameData = (game) => {
        return {
            sport: game.base_sport_type,
            country: game.country,
            league: game.base_league,
            home: game.home.name,
            away: game.away.name,
            profit: +game.profit.toFixed(2),
            betType: game.base_bet_type,
            betOption1: game.home.odds ? getBetOptionLabel(game.base_bet_type, game.home, true) : null,
            odd1: game.home && game.home.odds ? game.home.odds.toFixed(2) : null,
            odd1us: game.home && game.home.odds ? decimalToAmerican(game.home.odds) : null,
            provider1: game.home.provider,
            betOption2: game.away.odds ? getBetOptionLabel(game.base_bet_type, game.away, true) : null,
            odd2: game.away && game.away.odds ? game.away.odds.toFixed(2) : null,
            odd2us: game.away && game.away.odds ? decimalToAmerican(game.away.odds) : null,
            provider2: game.away.provider,
            playerName: null,
            betStarts: new Date(game.bet_starts),
        }
    }

    useEffect(() => {
        CommonService.listSampleSurebets()
            .then((response) => {
                let data = [];
                ["free", "essential", "platinum"].forEach(planType => {
                    data.push(formatGameData(response.data[planType]));
                });
                setGames(data);
            })
            .catch((err) => {
                console.log(err);
            })
      return () => {
        setGames([]);
      }
    }, [])

    const getSurebetRow = (gameData, idx) => {
        let planName, buttonText;
        switch (idx) {
            case 1:
                planName = "ESSENTIAL PLAN";
                buttonText = "Start 15-day Trial";
                break;
            case 2:
                planName = "PLATINUM PLAN";
                buttonText = "Start 15-day Trial";
                break;
            default:
                planName = "FREE PLAN";
                buttonText = "Start Free Plan";
        };
        return (
            <Grid key={idx} justifyContent="center" alignItems="center" container>
                <Grid item xs={9}>
                    <SurebetContainer gameData={gameData} landing={true} store={DUMMY_STORE} />
                </Grid>
                <Grid item xs={3}>
                    <StartPlanBox>
                        <PlanNameText>{planName}</PlanNameText>
                        <Link style={{ cursor: "pointer" }} to="pricing" smooth={true} offset={-80}>
                            <Button variant="contained" color="primary" size={isSmallScreen ? "small" : "medium"}>{buttonText}</Button>
                        </Link>
                    </StartPlanBox>
                </Grid>
            </Grid>
        )
    }

    return (
        <Wrapper className="container">
            <BetsContainer direction="column" justifyContent="center" alignItems="center" spacing={2}>
                {games && games.map((gameData, idx) => {
                    return getSurebetRow(gameData, idx)
                })}
            </BetsContainer>
        </Wrapper>
    );
}
