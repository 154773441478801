// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:960px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1280px)': {
      fontSize: pxToRem(lg)
    }
  };
}

const FONT_PRIMARY = 'Inter, sans-serif';

const p1 = {
  fontWeight: 400,
  lineHeight: 17 / 14,
  fontSize: pxToRem(14)
};
const p2 = {
  fontWeight: 400,
  lineHeight: 19 / 16,
  fontSize: pxToRem(16)
};
const p3 = {
  fontWeight: 400,
  lineHeight: 22 / 18,
  fontSize: pxToRem(18)
};

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 600,
    lineHeight: 56 / 48,
    fontSize: pxToRem(48),
    ...responsiveFontSizes({ sm: 62, md: 68, lg: 74 })
  },
  h2: {
    fontWeight: 600,
    lineHeight: 47 / 39,
    fontSize: pxToRem(39),
    ...responsiveFontSizes({ sm: 48, md: 52, lg: 56 })
  },
  h3: {
    fontWeight: 600,
    lineHeight: 40 / 33,
    fontSize: pxToRem(33),
    ...responsiveFontSizes({ sm: 36, md: 40, lg: 42 })
  },
  h4: {
    fontWeight: 600,
    lineHeight: 34 / 28,
    fontSize: pxToRem(28),
    ...responsiveFontSizes({ sm: 28, md: 32, lg: 36 })
  },
  h5: {
    fontWeight: 600,
    lineHeight: 28 / 23,
    fontSize: pxToRem(23),
    ...responsiveFontSizes({ sm: 24, md: 25, lg: 25 })
  },
  h6: {
    fontWeight: 600,
    lineHeight: 23 / 19,
    fontSize: pxToRem(19),
    ...responsiveFontSizes({ sm: 20, md: 20, lg: 20 })
  },
  subtitle1: {
    fontWeight: 400,
    lineHeight: 24 / 20,
    fontSize: pxToRem(20)
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 18 / 16,
    fontSize: pxToRem(16)
  },
  p1: { ...p1 },
  p2: { ...p2 },
  p3: { ...p3 },
  p1bold: { ...p1, fontWeight: 600 },
  p2bold: { ...p2, fontWeight: 600 },
  p3bold: { ...p3, fontWeight: 600 },
  caption: {
    fontWeight: 400,
    lineHeight: 14 / 12,
    fontSize: pxToRem(12)
  },
  label: {
    fontWeight: 700,
    lineHeight: 20 / 14,
    fontSize: pxToRem(14)
  },
  footer: {
    fontWeight: 400,
    lineHeight: 12 / 10,
    fontSize: pxToRem(10),
    letterSpacing: '0.02em',
  }
};

export default typography;
