import React from "react";
import { styled } from '@mui/system';
import { Typography, Stack, Button } from '@mui/material';
import { Link } from "react-scroll";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import "src/assets/style/landing.css";


const Wrapper = styled("section")(({ theme }) => ({
    backgroundColor: theme.palette.secondary[500],
    paddingTop: "50px !important",
    paddingBottom: "50px !important",
}));

const Title = styled(Typography)(({ theme }) => ({
    width: "60%",
    color: theme.palette.shades[0],
    fontWeight: 600,
    fontSize: "2rem",
    lineHeight: "2rem",
}));

const Text = styled(Typography)(({ theme }) => ({
    width: "60%",
    color: theme.palette.shades[0],
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.5rem",
}));


export default function EmailSubscription() {
    return (
        <Wrapper className="container flexCenterSpaced">
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
                <Title>Get the daily best deals in your email!</Title>
                <Text>
                    Arbitrage Betting is a great way to make profit at the bookies. If you want to make sure you receive our best deals, subscribe our newsletter and never miss out on any opportunity!
                </Text>
                <Link style={{ cursor: "pointer" }} to="footer" smooth={true} offset={-80}>
                    <Button variant="contained" color="primary" endIcon={<ArrowRightAltIcon sx={{ mt: -0.5 }} />} sx={{ borderRadius: "8px", textAlign: "left" }}>Subscribe to our Newsletter</Button>
                </Link>
            </Stack>
        </Wrapper>
    );
}
