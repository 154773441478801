import { Outlet } from 'react-router-dom';
import { styled } from '@mui/system';


const RootStyle = styled('div')(({ theme }) => ({
  backgroundImage: `url(${"/static/img/authBackground.png"})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const MainStyle = styled('div')(({ theme }) => ({
}));


export default function AuthLayout() {
  return (
    <RootStyle>
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
