// ----------------------------------------------------------------------

export default function FormControlLabel(theme) {
    return {
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            ...theme.typography.p1,
            color: theme.palette.neutral[600],
          }
        }
      }
    };
  }
  