import api from "src/api/Api";
import urls from "src/api/Urls";


export const listBetTypes = (params) => {
    return api.get(urls.listBetTypes, { params: params });
}

export const listTeams = (params) => {
    return api.get(urls.listTeams, { params: params });
}

export const listPlayers = (params) => {
    return api.get(urls.listPlayers, { params: params });
}

export const listGames = (params) => {
    return api.get(urls.listGames, { params: params });
}

export const listProviders = () => {
    return api.get(urls.listProviders);
}

export const listSurebets = (params) => {
    return api.get(urls.listSurebets, { params: params });
}

export const listSampleSurebets = () => {
    return api.get(urls.listSampleSurebets);
}
