export class Enum {
    constructor(...items) {
        items.forEach((item, i) => {
            this[item.key] = item;
        });
        Object.freeze(this);
    }

    *[Symbol.iterator]() {
        for (let item of Object.values(this)) yield item;
    }

    get length() {
        return Object.keys(this).length;
    }

    getLabelByValue(value) {
        for (let item of Object.values(this)) {
            if (item.value == value) {
                return item.label;
            }
        }
    }

    getKeyByValue(value) {
        for (let item of Object.values(this)) {
            if (item.value == value) {
                return item.key;
            }
        }
    }

    getValueByLabel(label) {
        for (let item of Object.values(this)) {
            if (item.label === label) {
                return item.value;
            }
        }
    }
};


export const americanToDecimal = (odd) => {
    if (odd > 0.0) {
        return ((odd / 100) + 1).toFixed(2);
    } else {
        return ((100 / Math.abs(odd)) + 1).toFixed(2);
    }
}

export const decimalToAmerican = (odd) => {
    if (odd < 2.0) {
        return ((-100.0) / (odd - 1.0)).toFixed(0);
    } else {
        return ((odd - 1.0) * 100).toFixed(0);
    }
}
