import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';


const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral[50],
}));


const Page = forwardRef(({ children, title = '', ...other }, ref) => (
  <Container sx={{ overflowX: "unset", width: "100%", height: "100%" }} ref={ref} {...other}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </Container>
));

export default Page;
