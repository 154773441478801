import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';


export const StyledLoginButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.shades[0],
    color: theme.palette.primary[600],
    fontWeight: 700,
    maxWidth: "110px",
    minWidth: "110px",
    borderRadius: "8px",
    padding: "24px 0",
    '&:hover': {
        backgroundColor: theme.palette.primary[400],
        color: theme.palette.shades[0],
    },
    '&:focus': {
        backgroundColor: theme.palette.primary[400],
        color: theme.palette.shades[0],
    },
}));

export const StyledSignupButton = styled(Button)(({ theme }) => ({
    fontWeight: 700,
    maxWidth: "110px",
    minWidth: "110px",
    borderRadius: "8px",
    padding: "24px 0",
}));

export const ListItem = styled("li")(({ theme }) => ({
    ...theme.typography.p2bold,
    padding: "10px 15px",
    cursor: "pointer",
}));

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
    textDecoration: "none",
    borderBottom: "none !important",
}));
