import { styled } from '@mui/system';
import { Typography, Stack, Button, Divider, Grid } from '@mui/material';
import { useStore } from 'src/store/Store';
import { useNavigate } from 'react-router-dom';


const Wrapper = styled(Grid)(({ theme }) => ({
    borderRadius: "10px",
    margin: theme.spacing(6, 0),
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2rem",
}));

const Price = styled(Typography)(({ theme }) => ({
    fontWeight: 800,
    fontSize: "2.5rem",
    lineHeight: "2rem",
}));

const Text = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: "0.9rem",
    lineHeight: "1.2rem",
    textAlign: "center",
}));

const PricingDivider = styled(Divider)(({ theme }) => ({
    backgroundColor: theme.palette.grey[500],
    width: "75%",
}));


export default function PricingTable({ price, planId, billingTerm, colorScheme, title, offers, buttonProps, buttonText }) {
    const [store, dispatch] = useStore();
    const navigate = useNavigate();

    const handlePlanSelect = () => {
        const planDetails = {
            planId: planId,
            price: price,
            title: title,
            offers: offers,
            billingTerm: billingTerm,
        };
        let path;
        if (store.token) {
          path = "/subscription";
        } else {
          path = "/auth/register";
        }
        navigate(path, {replace: false, state: planDetails});
    }

  return (
    <Wrapper item xs={12} md={4} sx={{ ...colorScheme, maxWidth: "340px !important" }} >
        <Stack justifyContent="flex-start" alignItems="center" spacing={4} sx={{ p: 4 }}>
            <Title>{title}</Title>
            <Price>{price}</Price>
            <Button onClick={() => handlePlanSelect()} { ... buttonProps } >{store && store.user?.trialUsed ? buttonText.replace("Start 15-day Trial", "Subscribe") : buttonText}</Button>
            <PricingDivider />
            {offers.map((offer, idx) => (
              <Text key={idx}>{offer}</Text>
            ))}
        </Stack>
    </Wrapper>
  );
}
