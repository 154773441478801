import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Autocomplete, Checkbox, TextField, Box, Button } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BaseAccordion from "src/components/BaseAccordion";
import { CommonService } from 'src/api/services';
import { useStore } from 'src/store/Store';


const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiFormControl-root': {
        border: `2px solid ${theme.palette.neutral[300]}`,
        borderRadius: "8px",
    },
    '&&& svg': {
        visibility: "visible",
        fill: theme.palette.primary[600],
        backgroundColor: theme.palette.neutral[100],
    },
}));


export default function ProviderFilter({ selectedProviders, setSelectedProviders }) {
    const [providers, setProviders] = useState([]);
    const [store, dispatch] = useStore();

    useEffect(() => {
        (async () => {
            const response = await CommonService.listProviders();
            const allProviders = response.data.map(provider => { return { id: provider.id, label: provider.name } });
            const userProviders = allProviders.filter(provider => store.user.providers?.includes(provider.id));
            setProviders(userProviders);
            setSelectedProviders(userProviders);
            dispatch({
                type: "PROVIDERS",
                payload: {
                    providers: allProviders,
                }
            });
        })();
        return () => {
            setProviders([]);
        }
    }, []);

    const handleChange = (event, values) => {
        setSelectedProviders(values);
    }

    const handleSelectAll = (event) => {
        setSelectedProviders(providers);
    }

    const getChildren = () => {
        return (
            <>
                <StyledAutocomplete
                    multiple
                    limitTags={3}
                    value={selectedProviders}
                    options={providers}
                    onChange={handleChange}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    style={{ minWidth: 200 }}
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Bookmakers" />
                    )}
                />
                <Box sx={{ textAlign: "right" }}>
                    <Button sx={{ minWidth: "80px"}} variant="text" color="primary" size="small" onClick={handleSelectAll}>{providers.length === selectedProviders.length ? "All Selected" : "Select All"}</Button>
                </Box>
            </>
        );
    }

    return (
        <BaseAccordion title="BOOKMAKERS" children={getChildren()} />
    );
}
