import { Stack, Slider, Chip } from "@mui/material";
import { styled } from "@mui/system";
import BaseAccordion from "src/components/BaseAccordion";


const StyledSlider = styled(Slider)(({ theme }) => ({
    height: 12,
    padding: theme.spacing(1.2, 0),
    '&.MuiSlider-root': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiSlider-track': {
        border: 'none',
        backgroundColor: theme.palette.primary[300],
    },
    '& .MuiSlider-rail': {
        border: 'none',
        backgroundColor: theme.palette.neutral[300],
    },
    '& .MuiSlider-thumb': {
        height: 14,
        width: 14,
        backgroundColor: theme.palette.primary[500],
        border: `2px solid ${theme.palette.primary[500]}`,
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    ...theme.typography.caption,
    minWidth: 44,
    backgroundColor: theme.palette.primary[200],
    color: theme.palette.neutral[700],
    "& span": {
        paddingRight: theme.spacing(0),
        paddingLeft: theme.spacing(0),
    },
}));


export default function ProfitFilter({ profitRange, setProfitRange }) {

    const formatValueText = (value) => {
        return `${value}%`
    }

    const handleChange = (event, newRange) => {
        setProfitRange(newRange);
    }

    const getChildren = () => {
        return (
            <Stack direction="row" spacing={2} sx={{ mt: 1, ml: 0, mr: -1 }}>
                <StyledChip label={formatValueText(profitRange[0])} />
                <StyledSlider
                    value={profitRange}
                    onChange={handleChange}
                    valueLabelDisplay="off"
                    valueLabelFormat={formatValueText}
                    min={0}
                    max={10}
                    step={0.5}
                />
                <StyledChip sx={{ '&.MuiChip-root': { marginLeft: "8px" } }}label={formatValueText(profitRange[1])} />
            </Stack>
        );
    }

    return (
        <BaseAccordion title="PROFIT" children={getChildren()} />
    );
}
