import { useState, useEffect } from "react";
import { Stack, Divider, Box, Button } from "@mui/material";
import {
    LeagueFilter,
    BetTypeFilter,
    BetDateFilter,
    ProfitFilter,
    ProviderFilter
} from "src/components/filterOptions";
import BaseAppSection from "./BaseAppSection";
import { LEAGUES, BET_TYPES, BET_DATES } from "src/constants";
import { getTimezonedDateInterval } from "src/utils/formatTime";
import { useStore } from "src/store/Store";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';


export default function AppFiltersSection({ loading, setSurebetFilters }) {
    const [leagues, setLeagues] = useState(Array(LEAGUES.length).fill(true));
    const [betTypes, setBetTypes] = useState(Array(BET_TYPES.length).fill(true));
    const [betDate, setBetDate] = useState(BET_DATES.ALL.value);
    const [profitRange, setProfitRange] = useState([0, 10]);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [store, dispatch] = useStore();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!(store.user.providers) || store.user.providers.length === 0) {
            enqueueSnackbar("You need to select desired bookmakers to view arbitrage bets", { variant: "warning" });
            navigate("/surebet/profile", { state: { activePage: "bookmakers" } });
        }
    }, []);

    useEffect(() => {
        applyFilters();
    }, [store.user.providers, store.providers, store.user.timezone]);

    const applyFilters = () => {
        let leagueIds = [];
        leagues.map((leagueSelected, idx) => {
            if (leagueSelected) {
                leagueIds.push([...LEAGUES][idx].value);
            }
        });

        let minBetDate, maxBetDate;
        if (betDate == BET_DATES.TODAY.value) {
            const today = getTimezonedDateInterval(store.user.timezone, "today");
            minBetDate = today.start;
            maxBetDate = today.end;
        } else if (betDate == BET_DATES.TOMORROW.value) {
            const tomorrow = getTimezonedDateInterval(store.user.timezone, "tomorrow");
            minBetDate = tomorrow.start;
            maxBetDate = tomorrow.end;
        }

        let onlyTeamBets = true;
        let onlyPlayerBets = true;
        let isMiddle = false;
        const selectedBetTypes = betTypes.map((betTypeSelected, idx) => {
            if (betTypeSelected) {
                return [...BET_TYPES][idx].value;
            }
        });
        selectedBetTypes.forEach(betType => {
            switch (betType) {
                case BET_TYPES.TEAM_SMARTBET.value:
                    onlyPlayerBets = false;
                    break;
                case BET_TYPES.PLAYER_SMARTBET.value:
                    onlyTeamBets = false;
                    break;
                case BET_TYPES.PLAYER_MIDDLE.value:
                    isMiddle = true;
                    break;
                default:
                    break;
            }
        });

        setSurebetFilters({
            league_ids: leagueIds.join(","),
            provider_ids: [...selectedProviders.map(provider => provider.id)].join(","),
            bet_date_min: minBetDate,
            bet_date_max: maxBetDate,
            profit_min: profitRange[0] === 0 ? 0.1 : profitRange[0],
            profit_max: profitRange[1],
            only_team_surebet: onlyTeamBets,
            only_player_surebet: onlyPlayerBets,
            is_middle: isMiddle,
            page_size: 10,
        });
    };

    const headerIcon = "/static/icons/filterIcon.svg";
    const headerText = "FILTER BY";
    const children = (
        <>
            <Stack
                justifyContent="flex-start"
                alignItems="left"
                spacing={2}
            >
                <LeagueFilter leagues={leagues} setLeagues={setLeagues} />
                <BetTypeFilter betTypes={betTypes} setBetTypes={setBetTypes} />
                <BetDateFilter betDate={betDate} setBetDate={setBetDate} />
                <ProfitFilter profitRange={profitRange} setProfitRange={setProfitRange} />
                <ProviderFilter selectedProviders={selectedProviders} setSelectedProviders={setSelectedProviders} />
            </Stack>
            <Divider sx={{ mt: 2.5 }} />
            <Box sx={{ textAlign: "right", mt: 2.5, mb: 1, mr: 0 }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => applyFilters()}
                    disabled={loading}
                >Apply Filters</Button>
            </Box>
        </>
    )

    return (
        <BaseAppSection
            headerIcon={headerIcon}
            headerText={headerText}
            children={children}
        />
    );
}
