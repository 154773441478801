import { styled } from '@mui/system';
import { TextField } from '@mui/material';


export const StyledTextField = styled(TextField)(({ theme }) => ({
    "&&& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active": {
        transition: "background-color 10000s",
        WebkitTextFillColor: `${theme.palette.shades[0]} !important`,
    },
    "& label.Mui-focused": {
        color: theme.palette.shades[0],
    }
}));
