import { useState } from "react";
import * as Yup from "yup";
import { styled } from '@mui/system';
import { Stack, Typography, Box, Grid, FilledInput, InputAdornment, IconButton } from '@mui/material';
import { Link as Href } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { Link } from "react-scroll";
import { StyledNavLink } from "../navigation/StyledComponents";
import Logo from "src/assets/svg/LogoWhite";
import Twitter from "src/assets/svg/social/Twitter";
import Instagram from "src/assets/svg/social/Instagram";
import Youtube from "src/assets/svg/social/Youtube";
import Facebook from "src/assets/svg/social/Facebook";
import { ProfileService } from "src/api/services";
import { useSnackbar } from 'notistack';
import "src/assets/style/landing.css";


const Wrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.primary[500],
    padding: theme.spacing(4, 6),
}));

const CopyrightText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    lineHeight: "0.6rem",
    color: theme.palette.primary[50],
}));

const FooterTitle = styled(Typography)(({ theme }) => ({
    fontSize: "1rem",
    lineHeight: "0.6rem",
    fontWeight: 500,
    color: theme.palette.shades[0],
    marginBottom: theme.spacing(2),
}));

const FooterText = styled(Typography)(({ theme }) => ({
    fontSize: "0.85rem",
    lineHeight: "0.6rem",
    fontWeight: 400,
    color: theme.palette.primary[50],
}));

const StyledInput = styled(FilledInput)(({ theme }) => ({
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.shades[0],
    alignItems: "center",
    verticalAlign: "center",
    ":hover": {
        backgroundColor: theme.palette.primary[400],
    },
    "& input::placeholder": {
        color: theme.palette.neutral[700],
    },
    "&.Mui-focused": {
        backgroundColor: theme.palette.primary[400],
        "input::placeholder": {
            color: theme.palette.neutral[200],
        },
    },
    "& .MuiInputBase-input": {
        marginTop: "-10px",
        marginBottom: "10px",
    },
    "&&& svg": {
        color: theme.palette.shades[0],
    }
}));


export default function Footer() {
    const currentYear = new Date().getFullYear();
    const [email, setEmail] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const EmailSubscriptionSchema = Yup.object().shape({
        email: Yup.string().email('Not a valid email'),
    });

    const emailSubscribe = () => {
        const validated = EmailSubscriptionSchema.isValidSync({ email: email });
        if (validated) {
            const payload = { email: email, subscribe: true };
            ProfileService.emailSubscribe(payload)
                .then((response) => {
                    if (response.status === 200) {
                        enqueueSnackbar("We will keep you informed about the news", { variant: "success" });
                        setEmail("");
                    } else { throw "email subscription failed" }
                })
                .catch((err) => {
                    let failMessage = "Your email could not be added to subscription list";
                    if (err.response.data.email) {
                        failMessage = err.response.data.email;
                    }
                    enqueueSnackbar(failMessage, { variant: "error" });
                })
        } else {
            enqueueSnackbar("Not a valid email", { variant: "error" });
        }
    }

    return (
        <Wrapper id="footer" className="container">
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Stack direction="column" spacing={2} sx={{ p: 2, mb: 2 }}>
                        <Logo width={140} height={50} />
                        <CopyrightText>Copyright © {currentYear} AlwaysWin.io</CopyrightText>
                        <CopyrightText>All rights reserved</CopyrightText>
                        <Stack direction="row" spacing={2}>
                            <Href href="https://twitter.com/AlwaysWin_io" target="_blank">
                                <Twitter />
                            </Href>
                            <Href href="https://www.facebook.com/alwayswin.io" target="_blank">
                                <Facebook />
                            </Href>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Stack direction="column" spacing={3} sx={{ p: 3, mb: 2 }}>
                        <FooterTitle>Company</FooterTitle>
                        {/* <StyledNavLink to="#">
                            <FooterText>Contact us</FooterText>
                        </StyledNavLink> */}
                        <Link activeClass="activeNoStyle" to="pricing" spy={true} smooth={true} offset={-80}>
                            <FooterText sx={{ cursor: "pointer" }}>Pricing</FooterText>
                        </Link>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Stack direction="column" spacing={3} sx={{ p: 3, mb: 2 }}>
                        <FooterTitle>Support</FooterTitle>
                        <StyledNavLink to="/terms-of-service">
                            <FooterText>Terms of service</FooterText>
                        </StyledNavLink>
                        <StyledNavLink to="/privacy-policy">
                            <FooterText>Privacy policy</FooterText>
                        </StyledNavLink>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Stack direction="column" spacing={3} sx={{ p: 3, mb: 2 }}>
                        <FooterTitle>Sign up for newsletter</FooterTitle>
                        <StyledInput
                            variant="filled"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Your email"
                            endAdornment={
                                <InputAdornment position="end">
                                  <IconButton edge="end" onClick={emailSubscribe}>
                                    <SendIcon />
                                  </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Wrapper>
    );
}
