import { Navigate, useRoutes } from 'react-router-dom';
//
import AppLayout from './layouts/main';
import AuthLayout from './layouts/AuthLayout';
import LandingLayout from './layouts/LandingLayout';
import Auth from './pages/Auth';
import NotFound from './pages/Page404';
import MainApp from './pages/MainApp';
import Landing from './pages/Landing';
import Profile from './pages/Profile';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { useStore } from 'src/store/Store';
import Blog from './pages/Blog';
import BlogPost from './components/blog/BlogPost';
// ----------------------------------------------------------------------


const handleAuthNavigation = (store) => {
  if (store.token !== null && store.user.selectedPlanDetails) {
    return <Navigate to="/subscription" replace={true} state={store.user.selectedPlanDetails} />;
  } else if (store.token !== null) {
    return <Navigate to="/surebet/surebets" />;
  } else {
    return <AuthLayout />;
  }
}

export default function Router() {
  const [store] = useStore();

  return useRoutes([
    {
      path: '/subscription',
      element: store.token === null ? <Navigate to="/" /> : <AuthLayout />,
      children: [
        { path: '/subscription', element: <Auth type="subscription" /> },
      ]
    },
    {
      path: '/surebet',
      element: store.token !== null ? <AppLayout /> : <Navigate to="/auth/login" />,
      children: [
        { path: "/surebet/surebets", element: <MainApp /> },
        { path: "/surebet/profile", element: <Profile /> },
      ]
    },
    {
      path: '/auth',
      element: handleAuthNavigation(store),
      children: [
        { path: '/auth/login', element: <Auth type="login" /> },
        { path: '/auth/register', element: <Auth type="register" /> },
        { path: '/auth/reset-password', element: <Auth type="resetPassword" /> },
        { path: '/auth/password-confirm', element: <Auth type="setResetPassword" /> },
      ]
    },
    {
      path: '/',
      element: <LandingLayout />,
      children: [
        { path: '/', element: <Landing /> },
        { path: '/terms-of-service', element: <TermsOfService /> },
        { path: '/privacy-policy', element: <PrivacyPolicy /> },
        { path: '/blog', element: <Blog /> },
        { path: '/blog/*', element: <BlogPost /> },
      ]
    },
    { path: '*', element: <NotFound /> }
  ]);
}
