import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { styled } from '@mui/system';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { StyledTextField } from 'src/components/auth/StyledComponents';
import { SubscriptionService } from 'src/api/services';
import { formatCardData, formatCreditCardNumber, formatCVC, formatExpirationDate } from './Formatters';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { useSnackbar } from 'notistack';
import { useStore } from 'src/store/Store';


const StyledText = styled(Typography)(({ theme }) => ({
    ...theme.typography.p2bold,
    color: theme.palette.shades[0],
    margin: theme.spacing(2, 0),
    lineHeight: theme.spacing(4),
}));


export default function SubscriptionForm({ planDetails }) {
    const navigate = useNavigate();
    const [focus, setFocus] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [store, dispatch] = useStore();

    const SubscriptionSchema = Yup.object().shape({
        number: Yup.string().required('Card number is required'),
        name: Yup.string().required('Card holder name is required'),
        expiry: Yup.string().required('Expiry date is required'),
        cvc: Yup.string().required('CVC is required'),
    });

    const handleInputFocus = (e) => {
        setFocus(e.target.name);
    }

    const formik = useFormik({
        initialValues: {
            number: "",
            name: "",
            expiry: "",
            cvc: "",
        },
        validationSchema: SubscriptionSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            const payload = {
                subscription: planDetails.planId,
                ...formatCardData(values),
            };
            SubscriptionService.createSubscription(payload)
                .then((response) => {
                    if (response.status === 201) {
                        enqueueSnackbar("You have successfully subscribed", { variant: "success" });
                        dispatch({
                            type: "UPDATE_PROFILE",
                            payload: {
                                user: {
                                    subscription: response.data,
                                },
                            }
                        });
                        navigate("/surebet/surebets", { replace: true });
                    } else { throw "subscription failed"; }
                })
                .catch((err) => {
                    let failMessage = "Transaction could not be finalized";
                    if (err.response.data.card) {
                        failMessage = err.response.data.card[0];
                    }
                    enqueueSnackbar(failMessage, { variant: "error" });
                })
                .finally(() => {
                    actions.setSubmitting(false);
                })
        }
    });

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, getFieldMeta, setFieldValue } = formik;

    return (
        <>
            <StyledText>You have selected {planDetails.billingTerm} {planDetails.title} plan for {planDetails.price}</StyledText>
            <Cards
                cvc={values.cvc}
                expiry={values.expiry}
                focused={focus}
                name={values.name}
                number={values.number}
            />
            <br />
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <StyledTextField
                            fullWidth
                            label="Card Number"
                            autoComplete="number"
                            {...getFieldProps('number')}
                            onFocus={handleInputFocus}
                            onChange={e => setFieldValue('number', formatCreditCardNumber(e.target.value))}
                            error={Boolean(touched.number && errors.number)}
                            helperText={touched.number && errors.number}
                        />

                        <StyledTextField
                            fullWidth
                            label="Card Holder"
                            autoComplete="name"
                            {...getFieldProps('name')}
                            onFocus={handleInputFocus}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                        />

                        <Stack direction="row">
                            <StyledTextField
                                fullWidth
                                label="Expiry"
                                {...getFieldProps('expiry')}
                                onFocus={handleInputFocus}
                                onChange={e => setFieldValue('expiry', formatExpirationDate(e.target.value))}
                                error={Boolean(touched.expiry && errors.expiry)}
                                helperText={touched.expiry && errors.expiry}
                                sx={{ mr: 2 }}
                            />

                            <StyledTextField
                                fullWidth
                                label="CVC"
                                {...getFieldProps('cvc')}
                                onFocus={handleInputFocus}
                                onChange={e => setFieldValue('cvc', formatCVC(e.target.value, values.number))}
                                onBlur={() => setFocus('number')}
                                error={Boolean(touched.cvc && errors.cvc)}
                                helperText={touched.cvc && errors.cvc}
                            />
                        </Stack>

                        <LoadingButton
                            fullWidth
                            color="primary"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            sx={{ borderRadius: "10px" }}
                        >
                            {store && store.user?.trialUsed ? "Subscribe" : "Start Trial"}
                        </LoadingButton>
                    </Stack>
                    <StyledText sx={{ fontSize: "0.75rem" }}>Your plan will be renewed {planDetails.billingTerm} if you don't cancel on time</StyledText>
                </Form>
            </FormikProvider>
        </>
    );
}
