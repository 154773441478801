import { useRef, useState } from 'react';
import { Button, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuPopover from 'src/components/MenuPopover';
import { TIMEZONES } from 'src/constants';
import { useStore } from 'src/store/Store';


const StyledButton = styled(Button)(({ theme }) => ({
    ...theme.typography.p1,
    color: theme.palette.neutral[500],
}));


export default function TimezonePopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [store, dispatch] = useStore();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        if (value) {
            dispatch({
                type: "UPDATE_PROFILE",
                payload: {
                    user: {
                        timezone: value,
                    },
                }
            });
        }
        setOpen(false);
    };

    return (
        <>
            <StyledButton
                disableRipple
                endIcon={<ExpandMoreIcon />}
                size="small"
                ref={anchorRef}
                onClick={handleOpen}
            >
                {TIMEZONES.getLabelByValue(store.user.timezone)}
            </StyledButton>

            <MenuPopover
                open={open}
                onClose={() => handleClose()}
                anchorEl={anchorRef.current}
            >
                {[...TIMEZONES].map((option) => (
                    <MenuItem
                        key={option.label}
                        onClick={() => handleClose(option.value)}
                        sx={{ typography: 'p1', p: 1 }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </MenuPopover>
        </>
    );
}
