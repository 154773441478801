import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Modal, Typography, Paper, Stack, IconButton, Box, Grid, TextField, InputAdornment, Divider, Tooltip, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import palette from "src/theme/palette";
import { ODD_FORMATS } from "src/constants";
import { fDateTimeSuffix } from "src/utils/formatTime";
import { decimalToAmerican, americanToDecimal } from "src/utils/Util";


const ModalContainer = styled(Paper)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    maxWidth: 800,
    backgroundColor: theme.palette.neutral[50],
    borderRadius: "16px",
    [theme.breakpoints.down('sm')]: {
        minWidth: 400,
        maxWidth: 500,
    }
}));

const ModalTextP2Bold = styled(Typography)(({ theme }) => ({
    ...theme.typography.p2bold,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.primary[600],
}));

const ModalTextP1Bold = styled(Typography)(({ theme }) => ({
    ...theme.typography.p1bold,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.primary[600],
}));

const ModalTextCaption = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    padding: theme.spacing(0, 1),
    textAlign: "center",
    color: theme.palette.neutral[600],
}));

const FormulaCharacter = styled(Typography)(({ theme }) => ({
    ...theme.typography.h4,
    textAlign: "center",
    padding: theme.spacing(1),
    color: theme.palette.neutral[500],
}));

const TeamsWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary[100],
    padding: theme.spacing(3, 1),
}));

const ItemBox = styled(Box)(({ theme }) => ({
    ...theme.typography.p2bold,
    backgroundColor: theme.palette.success[400],
    color: theme.palette.shades[0],
    padding: theme.spacing(2.5),
    borderRadius: "8px",
    textAlign: "center",
}));

const OddInput = styled(TextField)(({ theme }) => ({
    "& input": {
        ...theme.typography.p2bold,
        backgroundColor: theme.palette.primary[300],
        color: theme.palette.neutral[700],
        textAlign: "center",
    }
}));

const StakeInput = styled(TextField)(({ theme }) => ({
    "& input": {
        ...theme.typography.p2bold,
        backgroundColor: theme.palette.shades[0],
        color: theme.palette.neutral[700],
        textAlign: "center",
    }
}));

const InvestInput = styled(TextField)(({ theme }) => ({
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: "8px",
    margin: theme.spacing(0.5, 0),
    ":hover": {
        backgroundColor: theme.palette.shades[0],
    },
    "& .Mui-focused": {
        backgroundColor: theme.palette.shades[0],
    },
    "& .MuiInputBase-input": {
        marginTop: "-8px",
        marginBottom: "9px",
        ...theme.typography.p2bold,
        color: theme.palette.neutral[700],
        [theme.breakpoints.down('sm')]: {
            ...theme.typography.p1bold,
        }
    },
    "&&& .MuiInputAdornment-filled p": {
        ...theme.typography.p2bold,
        margin: theme.spacing(0, 1),
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            ...theme.typography.p1bold,
        }
    },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    ...theme.typography.p1,
    width: "75%",
    margin: "auto",
    padding: theme.spacing(3, 0.5),
}));


const calculateArbitrage = (odd1, odd2) => {
    return ((1 / odd1) * 100) + ((1 / odd2) * 100);
}

const calculateProfit = (arbitrage) => {
    return (100 / (arbitrage / 100)) - 100;
}

const getIndividualStake = (odd, invest, arb) => {
    if (odd && odd !== "0") {
        return ((invest * ((1 / odd) * 100)) / arb).toFixed(2);
    }
    return "0";
}

export default function Calculator({ event, store, open, setOpen }) {
    const initArb = calculateArbitrage(event.odd1, event.odd2);
    const initInvestAmount = (store.user.defaultInvestAmount || 100).toFixed(2);
    const initStake1 = getIndividualStake(event.odd1, initInvestAmount, initArb);
    const initStake2 = getIndividualStake(event.odd2, initInvestAmount, initArb);
    const [investAmount, setInvestAmount] = useState(initInvestAmount);
    const [odd1, setOdd1] = useState(event.odd1);
    const [odd2, setOdd2] = useState(event.odd2);
    const [odd1us, setOdd1us] = useState(event.odd1us);
    const [odd2us, setOdd2us] = useState(event.odd2us);
    const [arbitrage, setArbitrage] = useState(initArb);
    const [profit, setProfit] = useState(event.profit);
    const [stake1, setStake1] = useState(initStake1);
    const [stake2, setStake2] = useState(initStake2);
    const [lockStakes, setLockStakes] = useState(true);
    const [stakesAutoCalculated, setStakesAutoCalculated] = useState(false);
    const [investUpdatedManually, setInvestUpdatedManually] = useState(false);

    useEffect(() => {
        if (odd1 > 0 && odd2 > 0) {
            const _arbitrage = calculateArbitrage(odd1, odd2);
            const _profit = calculateProfit(_arbitrage);
            setArbitrage(_arbitrage);
            setProfit(_profit);
            setLockStakes(true);
            setStake1(getIndividualStake(odd1, investAmount, _arbitrage));
        }
    }, [odd1, odd2, odd1us, odd2us]);

    useEffect(() => {
        if (open === false) {
            setOdd1(event.odd1);
            setOdd2(event.odd2);
            setOdd1us(event.odd1us);
            setOdd2us(event.odd2us);
            setInvestAmount(initInvestAmount);
            setStake1(initStake1);
            setStake2(initStake2);
            setLockStakes(true);
            setStakesAutoCalculated(false);
            setInvestUpdatedManually(false);
        }
    }, [open]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (investUpdatedManually) {
                setLockStakes(true);
                setStake1(getIndividualStake(odd1, investAmount, arbitrage));
                setStake2(getIndividualStake(odd2, investAmount, arbitrage));
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [investAmount]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setInvestUpdatedManually(false);
            if (+stake1) {
                const _stake2 = ((odd1 * stake1) / odd2).toFixed(2);
                if (lockStakes) {
                    if (!stakesAutoCalculated) {
                        setStakesAutoCalculated(true);
                        setStake2(_stake2);
                    } else {
                        setStakesAutoCalculated(false);
                    }
                    setInvestAmount((+stake1 + +_stake2).toFixed(2));
                } else {
                    setInvestAmount((+stake1 + +stake2).toFixed(2));
                }
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [stake1]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setInvestUpdatedManually(false);
            if (+stake2) {
                const _stake1 = ((odd2 * stake2) / odd1).toFixed(2);
                if (lockStakes) {
                    if (!stakesAutoCalculated) {
                        setStakesAutoCalculated(true);
                        setStake1(_stake1);
                    } else {
                        setStakesAutoCalculated(false);
                    }
                    setInvestAmount((+_stake1 + +stake2).toFixed(2));
                } else {
                    setInvestAmount((+stake1 + +stake2).toFixed(2));
                }
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [stake2]);

    const handleLockStakes = (e) => {
        setLockStakes(e.target.checked);
    }

    const handleOddChange = (value, setValue, setValueUS) => {
        if (+value) {
            let odd, oddUS;
            if (store.user.oddFormat === ODD_FORMATS.US.value) {
                odd = americanToDecimal(value);
                oddUS = value;
            } else {
                odd = value;
                oddUS = decimalToAmerican(value);
            }
            setValue(odd);
            setValueUS(oddUS);
        } else {
            if (store.user.oddFormat === ODD_FORMATS.US.value) {
                setValueUS(value);
            } else {
                setValue(value);
            }
        }
    }

    const handleStakeChange = (value, setValue) => {
        setValue(value);
    }

    const handleInvestAmountChange = (e) => {
        setInvestUpdatedManually(true);
        setInvestAmount(e.target.value);
    }

    const getReturnAmount = () => {
        if (!investUpdatedManually) {
            const return1 = odd1 * stake1;
            const return2 = odd2 * stake2;
            return return1 <= return2 ? return1.toFixed(2) : return2.toFixed(2);
        }
        if (investAmount && +investAmount > 0) {
            return (((profit / 100) * investAmount) + +investAmount).toFixed(2);
        }
        return "0";
    }

    const getIndividualBetRow = (betOption, provider, odd, oddUS, setOdd, setOddUS, stake, setStake) => {
        const returnAmount = (stake * odd).toFixed(2);
        return (
            <>
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 1 }}>
                    <Grid item xs={3}>
                        <ModalTextCaption sx={{ textTransform: "uppercase" }}>{betOption}</ModalTextCaption>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3}>
                        <ModalTextCaption>STAKE</ModalTextCaption>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3}>
                        <ModalTextCaption>RETURN</ModalTextCaption>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={3}>
                        <ItemBox sx={{ backgroundColor: palette.primary[300], color: palette.neutral[700], textTransform: "uppercase" }}>
                            {provider}
                            <OddInput variant="standard" value={store.user.oddFormat === ODD_FORMATS.US.value ? oddUS : odd} onChange={(e) => handleOddChange(e.target.value, setOdd, setOddUS)} />
                        </ItemBox>
                    </Grid>
                    <Grid item xs={1}>
                        <FormulaCharacter> x </FormulaCharacter>
                    </Grid>
                    <Grid item xs={3}>
                        <ItemBox sx={{ backgroundColor: palette.shades[0], color: palette.neutral[700], border: `1px solid ${palette.neutral[200]}`, pt: 1.1 }}>
                            <StakeInput sx={{ mb: -1 }} variant="standard" value={stake} onChange={(e) => handleStakeChange(e.target.value, setStake)} />
                        </ItemBox>
                    </Grid>
                    <Grid item xs={1}>
                        <FormulaCharacter> = </FormulaCharacter>
                    </Grid>
                    <Grid item xs={3}>
                        <ItemBox sx={{ backgroundColor: palette.neutral[100], color: palette.neutral[700], border: `1px solid ${palette.neutral[200]}` }}>{returnAmount}</ItemBox>
                    </Grid>
                </Grid>
            </>
        )
    }

    const getFooter = () => {
        const returnAmount = getReturnAmount();
        const sureProfit = (returnAmount - +investAmount).toFixed(2);
        return (
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" sx={{ pt: 2, pb: 2 }}>
                <Stack direction="column" justifyContent="center" alignItems="center">
                    <ModalTextCaption>STAKE</ModalTextCaption>
                    <ModalTextP1Bold sx={{ color: palette.neutral[700] }}>{investAmount}</ModalTextP1Bold>
                </Stack>
                <Stack direction="column" justifyContent="center" alignItems="center">
                    <ModalTextCaption>ARBITRAGE</ModalTextCaption>
                    <ModalTextP1Bold sx={{ color: palette.neutral[700] }}>{(100 - arbitrage).toFixed(2)}%</ModalTextP1Bold>
                </Stack>
                <Stack direction="column" justifyContent="center" alignItems="center">
                    <ModalTextCaption>RETURNS</ModalTextCaption>
                    <ModalTextP1Bold sx={{ color: palette.neutral[700] }}>{returnAmount}</ModalTextP1Bold>
                </Stack>
                <Stack direction="column" justifyContent="center" alignItems="center">
                    <ModalTextCaption>SURE PROFIT</ModalTextCaption>
                    <ModalTextP1Bold sx={{ color: sureProfit >= 0 ? palette.success[600] : palette.error[600] }}>{sureProfit}</ModalTextP1Bold>
                </Stack>
            </Grid>
        )
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <ModalContainer elevation={24}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pr: 1, pl: 1 }}>
                    <ModalTextP2Bold>SUREBET CALCULATOR</ModalTextP2Bold>
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <TeamsWrapper>
                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={0}>
                        <ModalTextP2Bold sx={{ fontWeight: 600 }}>{event.home} - {event.away}</ModalTextP2Bold>
                        {event.playerName ? <ModalTextP1Bold sx={{ fontWeight: 600, mt: -2.5 }}>{event.playerName}</ModalTextP1Bold> : null}
                        <Box sx={{ display: "inline-flex", pb: 2 }}>
                            <ModalTextCaption>Game Starts At:</ModalTextCaption>
                            <ModalTextCaption sx={{ color: palette.primary[400] }}>{fDateTimeSuffix(event.betStarts, store.user.timezone)}</ModalTextCaption>
                        </Box>
                    </Stack>
                </TeamsWrapper>
                <ModalTextP1Bold>HOW MUCH DO YOU WANT TO BET?</ModalTextP1Bold>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}>
                        <InvestInput
                            variant="filled"
                            value={investAmount}
                            onChange={handleInvestAmountChange}
                            InputProps={{
                                startAdornment: <InputAdornment position="start" sx={{ mt: 0, mb: 2 }}>INVEST:</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormulaCharacter sx={{ mb: 1 }}> = </FormulaCharacter>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                        <ItemBox sx={{ backgroundColor: getReturnAmount() - investAmount >= 0 ? palette.success[400] : palette.error[600] }}>RETURN: {getReturnAmount()}</ItemBox>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <StyledDivider>INDIVIDUAL BETS</StyledDivider>
                <Box sx={{ textAlign: "center", mt: -2, mb: 0.5 }}>
                    <Tooltip title="Lock stake ratio to keep sure profit. If disabled, manual stake input will be available to allow biased betting">
                        <FormControlLabel sx={{ cursor: "help" }} control={<Checkbox checked={lockStakes} onChange={handleLockStakes} />} label="Lock Stake Ratio" />
                    </Tooltip>
                </Box>
                {getIndividualBetRow(event.betOption1, event.provider1, odd1, odd1us, setOdd1, setOdd1us, stake1, setStake1)}
                {getIndividualBetRow(event.betOption2, event.provider2, odd2, odd2us, setOdd2, setOdd2us, stake2, setStake2)}
                {getFooter()}
            </ModalContainer>
        </Modal>
    );
}
