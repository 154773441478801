export default function AccordionSummary(theme) {
    return {
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding: '0px 16px',
                    margin: 0,
                    '&.Mui-expanded': {
                        margin: 0,
                        minHeight: '50px',
                    }
                }
            }
        }
    };
}
