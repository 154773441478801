import { useRef, useState } from 'react';
import { Button, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuPopover from 'src/components/MenuPopover';
import { ODD_FORMATS } from 'src/constants';
import { useStore } from 'src/store/Store';
import { ProfileService } from 'src/api/services';


const StyledButton = styled(Button)(({ theme }) => ({
    ...theme.typography.p1,
    color: theme.palette.neutral[500],
}));


export default function OddFormatPopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [store, dispatch] = useStore();

    const updateDefaultOddFormat = (oddFormat) => {
        const payload = { default_odd_format: oddFormat };
        ProfileService.updateProfile(store.user.id, payload)
            .then((response) => {
                dispatch({
                    type: "UPDATE_PROFILE",
                    payload: {
                        user: {
                            oddFormat: oddFormat,
                        },
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        if (value) {
            updateDefaultOddFormat(value);
        }
        setOpen(false);
    };
    return (
        <>
            <StyledButton
                disableRipple
                endIcon={<ExpandMoreIcon />}
                size="small"
                ref={anchorRef}
                onClick={handleOpen}
            >
                {ODD_FORMATS.getLabelByValue(store.user.oddFormat)}
            </StyledButton>

            <MenuPopover
                open={open}
                onClose={() => handleClose()}
                anchorEl={anchorRef.current}
            >
                {[...ODD_FORMATS].map((option) => (
                    <MenuItem
                        key={option.label}
                        onClick={() => handleClose(option.value)}
                        sx={{ typography: 'p1', p: 1 }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </MenuPopover>
        </>
    );
}
