import React from "react";
import { styled } from '@mui/system';
import { Paper, Stack, Typography, Box, Grid } from '@mui/material';
import "src/assets/style/landing.css";
import Info1 from "src/assets/img/landing/about/info1.png";
import Info2 from "src/assets/img/landing/about/info2.png";
import Info3 from "src/assets/img/landing/about/info3.png";
import Info4 from "src/assets/img/landing/about/info4.png";
import Checked from "src/assets/img/landing/about/checked.png";


const INFO_ITEMS = [
    {
        source: Info1,
        infoText: "20+",
        text: "Bookies Available",
    },
    {
        source: Info2,
        infoText: "10000+",
        text: "Opportunities Available",
    },
    {
        source: Info3,
        infoText: "7/24",
        text: "All-Day Arbitrage Scanning",
    },
    {
        source: Info4,
        infoText: "5%",
        text: "Profits on Average",
    },
];

const CHECKED_TEXTS = [
    "Our arbitrage bet finding system scans countless number of bookies, more often than any other system and brings you the guaranteed betting profits each minute.",
    "AlwaysWin analyzes and calculates betting arbitrages continuously so that you can focus on much return you want to get from your arbitrage bets.",
    "AlwaysWin covers a variety of sports in the US market and brings you betting arbitrages from countless number of bookies."
];

const Wrapper = styled("section")(({ theme }) => ({
    paddingTop: "80px",
    width: "100%",
    background: `linear-gradient(180deg, ${theme.palette.primary[500]} 30%, ${theme.palette.shades[0]} 0%)`,
    [theme.breakpoints.down("md")]: {
        background: `linear-gradient(180deg, ${theme.palette.primary[500]} 30%, ${theme.palette.shades[0]} 0%)`,
    },
}));

const InfoImageWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    position: "relative",
    borderRadius: "50%",
    padding: theme.spacing(1.6, 1),
    width: "3.2rem",
    height: "auto",
    backgroundColor: theme.palette.neutral[50],
}));

const Image = styled("img")(({ theme }) => ({
    zIndex: 9,
}));

const InfoWrapper = styled(Paper)(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(4),
    borderRadius: "8px",
    marginTop: theme.spacing(6)
}));

const InfoTextHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary[600],
    margin: theme.spacing(1, 0),
    fontWeight: 800,
    fontSize: "2rem",
    lineHeight: "2.2rem",
}));

const InfoText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary[600],
    fontWeight: 500,
    fontSize: "0.85rem",
    lineHeight: "1.0rem",
    textAlign: "center",
}));

const FeatureWrapper = styled(Paper)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
}));

const CheckedText = styled(Typography)(({ theme }) => ({
    width: "60%",
    color: theme.palette.neutral[800],
    fontWeight: 400,
    fontSize: "0.85rem",
    lineHeight: "1.0rem",
    alignItems: "flex-start",
    display: "inline-flex",
    margin: theme.spacing(2, 0),
}));


export default function About() {
    const getInfoComponent = (idx, infoItem) => {
        return (
            <Grid className="flexCenterSpaced" sx={{ flexDirection: "column" }} item xs={6} sm={3}>
                <InfoImageWrapper>
                    <Image src={infoItem.source} />
                </InfoImageWrapper>
                <InfoTextHeader>{infoItem.infoText}</InfoTextHeader>
                <InfoText>{infoItem.text}</InfoText>
            </Grid>
        )
    }

    const getCheckedComponent = (idx, text) => {
        return (
            <CheckedText key={idx}><Image src={Checked} sx={{ mr: 2 }} />{text}</CheckedText>
        )
    }

    return (
        <Wrapper id="about" className="container flexCenter">
            <Stack sx={{ width: "100%" }} spacing={5}>
                <InfoWrapper className="flexCenterSpaced" elevation={6} >
                    <Grid justifyContent="center" alignItems="center" container spacing={2} >
                        {INFO_ITEMS.map((infoItem, idx) => { return getInfoComponent(idx, infoItem) })}
                    </Grid>
                </InfoWrapper>
                <FeatureWrapper>
                    {CHECKED_TEXTS.map((text, idx) => { return getCheckedComponent(idx, text) })}
                </FeatureWrapper>
            </Stack>
        </Wrapper>
    );
}
