import { format, formatDistanceToNow, addDays } from 'date-fns';
import { formatInTimeZone, utcToZonedTime, toDate } from 'date-fns-tz';


export const API_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const API_DATETIME_FORMAT_DATE_START = "yyyy-MM-dd'T'00:00:00XXX";
export const API_DATETIME_FORMAT_DATE_END = "yyyy-MM-dd'T'23:59:59XXX";
// ----------------------------------------------------------------------

export function fDate(date, tz = null) {
  if (tz) {
    return formatInTimeZone(new Date(date), tz, 'dd MMMM');
  }
  return format(new Date(date), 'dd MMMM');
}

export function fTimeSuffix(date, tz = null) {
  if (tz) {
    return formatInTimeZone(new Date(date), tz, 'p');
  }
  return format(new Date(date), 'p');
}

export function fDateTime(date, tz = null) {
  if (tz) {
    return formatInTimeZone(new Date(date), tz, 'dd MMM yyyy HH:mm');
  }
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date, tz = null) {
  if (tz) {
    return formatInTimeZone(new Date(date), tz, 'dd MMMM p');
  }
  return format(new Date(date), 'dd MMMM p');
}

export function fDateTimeSuffixFull(date, tz = null) {
  if (tz) {
    return formatInTimeZone(new Date(date), tz, 'dd MMMM yyyy p');
  }
  return format(new Date(date), 'dd MMMM yyyy p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fDateTimeApi(date, tz = null, formatStr = API_DATETIME_FORMAT) {
  if (tz) {
    return formatInTimeZone(date, tz, formatStr);
  }
  return format(new Date(date), formatStr);
}

export function getTimezonedDateInterval(tz, relativeDay = "today") {
  let date = new Date();
  if (relativeDay === "tomorrow") {
    date = addDays(date, 1);
  };
  const tzDate = toDate(utcToZonedTime(+date, tz), { timeZone: tz });
  const start = fDateTimeApi(tzDate, tz, API_DATETIME_FORMAT_DATE_START);
  const end = fDateTimeApi(tzDate, tz, API_DATETIME_FORMAT_DATE_END);
  return { start: start, end: end };
}
