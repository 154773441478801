import { useState, useEffect } from "react";
import UpcomingGameContainer from "./upcomingGame/UpcomingGameContainer";
import BaseAppSection from "./BaseAppSection";
import BasePagination from "./BasePagination";
import BaseSkeleton from "./BaseSkeleton";
import { CommonService } from 'src/api/services';
import { useStore } from 'src/store/Store';


export default function AppUpcomingGamesSection() {
    const [games, setGames] = useState([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [store, dispatch] = useStore();

    useEffect(() => {
        fetchData();
    }, [page]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchData = () => {
        setLoading(true);
        const params = { page: page, page_size: 10 };
        CommonService.listGames(params)
            .then((response) => {
                setTotalCount(response.data.count);
                const games = response.data.results.map(game => {
                    return {
                        home: game.home,
                        away: game.away,
                        league: game.league,
                        sport: game.base_sport_type,
                        country: game.country,
                        starts: new Date(game.starts),
                    }
                });
                setGames(games);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const headerIcon = "/static/icons/clockIcon.svg";
    const headerText = "UPCOMING GAMES";
    let children;
    if (loading) {
        children = [...Array(10).keys()].map(idx => <BaseSkeleton key={idx} height={128} sx={{ mb: "16px" }} />);
    } else {
        children = games.map((game, idx) => {
            return <UpcomingGameContainer key={idx} gameData={game} store={store} />
        });
    };
    children.push(
        <BasePagination key={"basepagination"} count={Math.ceil(totalCount / 10)} page={page} onChange={handlePageChange} />
    );
    return (
        <BaseAppSection
            headerIcon={headerIcon}
            headerText={headerText}
            children={children}
        />
    );
}
