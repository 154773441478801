
import { Box } from '@mui/material';

// ----------------------------------------------------------------------


export default function SvgIconStyle({ src, sx }) {
  return (
    <Box
      component="img"
      src={src}
      sx={{
        width: 24,
        height: 24,
        ...sx
      }}
    />
  );
}
