import api from "src/api/Api";
import urls from "src/api/Urls";


export const listPlans = (params) => {
    return api.get(urls.listPlans, { params: params });
}

export const createSubscription = (payload) => {
    return api.post(urls.createSubscription, payload);
}

export const cancelSubscription = (id) => {
    return api.post(urls.cancelSubscription(id));
}

export const createCreditCard = (payload) => {
    return api.post(urls.createCreditCard, payload);
}

export const validatePromoCode = (payload) => {
    return api.post(urls.validatePromoCode, payload);
}
