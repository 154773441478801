import { styled } from '@mui/system';
import { makeStyles } from "@mui/styles";
import { Box, Typography } from '@mui/material';
import { BlogService }from "src/api/services"
import { useEffect, useState } from "react";
import parse from 'html-react-parser';


const useStyles = makeStyles(theme => ({
    contentClass: {
        "& p, h1, h2, h3, h4, h5, h6, img, li": {
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        "& li::before": {
            content: "' • '",            
        },
        "& img": {
            objectFit: "contain",
            [theme.breakpoints.down("sm")]: {
                maxHeight: "200px",
            }
        },
    },
}));

const Wrapper = styled("section")(({ theme }) => ({
    flexDirection: "column",
}));

const BlogContent = styled(Box)(({ theme }) => ({
    wordWrap: "break-word",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    }
}));

const ImageBox = styled(Box)(({ theme }) => ({
    maxHeight: "60vh",
    [theme.breakpoints.down("sm")]: {
        maxHeight: "40vh",
    },
    marginBottom: "10px"
}));

const BlogTitle = styled(Typography)(({ theme }) => ({
    width: "60%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    }
}));


export default function BlogPost() {
    const classes = useStyles();
    const [blogPostDetails, setBlogPostDetails] = useState({});

    useEffect(() => {
        const slugs = new URL(window.location.href).pathname.match(/[^\/]+/g);
        if (slugs.length > 0) {
            BlogService.getBlogPostFromSlug(slugs.at(-1))
            .then((response) => {
                setBlogPostDetails(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, []);

    return (
        <Wrapper id="blog" className="container flexCenterSpaced">
            {blogPostDetails.image && <ImageBox
                component="img"
                src={blogPostDetails.image}
            />}
            <BlogTitle gutterBottom variant="h3" component="div">
                {blogPostDetails.title}
            </BlogTitle>
            <BlogContent className={classes.contentClass}>
                {parse(blogPostDetails.content ?? '')}
            </BlogContent>
        </Wrapper>
    );
}
