import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider, useField } from 'formik';
// material
import { Stack, IconButton, InputAdornment, Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';
import { StyledTextField } from 'src/components/auth/StyledComponents';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useStore } from 'src/store/Store';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { parsePhoneNumber } from 'libphonenumber-js';
import { ProfileService } from 'src/api/services';
import { TIMEZONES, ODD_FORMATS } from 'src/constants';
import { useSnackbar } from 'notistack';


const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    ...theme.typography.p1,
    '& span': {
        color: theme.palette.primary[500],
    },
    '& span.MuiFormControlLabel-label': {
        color: `${theme.palette.shades[0]} !important`,
    },
}));

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
    fontFamily: "'Inter', sans-serif !important",
    '&&& input': {
        borderRadius: "8px",
        fontSize: "1rem",
        lineHeight: "1.4375em",
        width: "100%",
        background: "transparent",
        color: `${theme.palette.shades[0]}`,
        border: `1px solid ${theme.palette.shades[0]}`,
    },
    '&&& input:focus': {
        color: `${theme.palette.shades[0]}`,
        border: `2px solid ${theme.palette.shades[0]}`,
        boxShadow: "none",
    },
    '&&& .special-label': {
        background: "transparent",
        color: theme.palette.shades[0],
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "23px",
        top: "-1px",
        left: "48px",
    },
    '&&& input.search-box': {
        color: `${theme.palette.primary[500]}`,
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "23px",
        top: "-1px",
        left: "48px",
    },
    "&&& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active": {
        transition: "background-color 10000s",
        WebkitTextFillColor: `${theme.palette.shades[0]} !important`,
    },
}));

const CheckboxWithLabel = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <StyledFormControlLabel control={<Checkbox {...field} {...props} />} label={label} />
    )
}


export default function RegisterForm({ planDetails }) {
    const [showPassword, setShowPassword] = useState(false);
    const [selectedCountry, setCountry] = useState("us");
    const [store, dispatch] = useStore();
    const { enqueueSnackbar } = useSnackbar();

    const validatePhoneNumber = (value) => {
        try {
            const phoneNumber = parsePhoneNumber(value, selectedCountry.toUpperCase());
            return phoneNumber.isValid();
        } catch (error) {
            return false;
        }
    };

    const handlePhoneNumberChange = (value, country, setFieldValue) => {
        setCountry(country.countryCode);
        setFieldValue("phoneNumber", value);
    };

    const RegisterSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name is required'),
        lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
        phoneNumber: Yup.string().required("Phone number is required").test("isValidPhoneNumber", "Invalid phone number", value => validatePhoneNumber(value)),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().min(8, 'Too Short!').required('Password is required'),
        terms: Yup.boolean().oneOf([true], 'Terms and conditions must be accepted'),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            password: '',
            terms: false,
        },
        validationSchema: RegisterSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            const payload = {
                email: values.email,
                first_name: values.firstName,
                last_name: values.lastName,
                password: values.password,
                mobile_phone: values.phoneNumber,
                country: selectedCountry.toUpperCase(),
            };
            ProfileService.register(payload)
                .then((response) => {
                    if (response.status === 201) {
                        enqueueSnackbar("You have successfully registered", { variant: "success" });
                        dispatch({
                            type: "LOGIN",
                            payload: {
                                token: response.data.token,
                                user: {
                                    email: response.data.email,
                                    firstName: response.data.first_name,
                                    lastName: response.data.last_name,
                                    phoneNumber: response.data.mobile_phone,
                                    id: response.data.id,
                                    isStaff: false,
                                    isSuperUser: false,
                                    selectedPlanDetails: planDetails,
                                    timezone: TIMEZONES.EST.value,
                                    oddFormat: ODD_FORMATS.US.value,
                                    trialUsed: response.data.is_trial_used_before,
                                }
                            }
                        });
                    } else { throw "register failed"; }
                })
                .catch((err) => {
                    let failMessage = "Register failed";
                    if (err.response.data.email) {
                        failMessage = err.response.data.email[0];
                    }
                    enqueueSnackbar(failMessage, { variant: "error" });
                })
                .finally(() => {
                    actions.setSubmitting(false);
                })
        }
    });

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, getFieldMeta, setFieldValue } = formik;
    const tosError = getFieldMeta('terms').error;
    const phoneNumberError = getFieldMeta('phoneNumber').error;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <Stack direction={{ xs: 'column', sm: 'row' }}>
                            <StyledTextField
                                fullWidth
                                label="First name"
                                {...getFieldProps('firstName')}
                                error={Boolean(touched.firstName && errors.firstName)}
                                helperText={touched.firstName && errors.firstName}
                                sx={{ mr: 2 }}
                            />

                            <StyledTextField
                                fullWidth
                                label="Last name"
                                {...getFieldProps('lastName')}
                                error={Boolean(touched.lastName && errors.lastName)}
                                helperText={touched.lastName && errors.lastName}
                            />
                        </Stack>

                        <FormControl >
                            <StyledPhoneInput
                                specialLabel="Phone Number"
                                inputProps={{ name: "phoneNumber", notched: true }}
                                country={selectedCountry}
                                value={values.phoneNumber}
                                enableSearch={true}
                                preferredCountries={['ca', 'us']}
                                onChange={(value, country, e, f) => handlePhoneNumberChange(value, country, setFieldValue)}
                            />
                            {phoneNumberError ? <FormHelperText error>{phoneNumberError}</FormHelperText> : null}
                        </FormControl>

                        <StyledTextField
                            fullWidth
                            type="email"
                            label="Email"
                            autoComplete="email"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />

                        <StyledTextField
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            {...getFieldProps('password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />

                        <CheckboxWithLabel id="terms" name="terms" label="I accept the terms and conditions" />
                        {tosError ? <FormHelperText error>{tosError}</FormHelperText> : null}

                        <LoadingButton
                            fullWidth
                            color="primary"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            sx={{ borderRadius: "10px" }}
                        >
                            Register
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </>
    );
}
