import { Enum } from "../utils/Util";


export const LEAGUES = new Enum(
    { key: "NFL", value: 5, label: "National Football League", short: "NFL" },
    { key: "MLB", value: 7, label: "Major League Baseball", short: "MLB" },
    { key: "NBA", value: 4, label: "National Basketball Association", short: "NBA" },
    { key: "NHL", value: 6, label: "National Hockey League", short: "NHL" },
);

export const BET_TYPES = new Enum(
    { key: "TEAM_SMARTBET", value: 1, label: "Team SmartBets" },
    { key: "PLAYER_SMARTBET", value: 2, label: "Player SmartBets" },
    { key: "PLAYER_MIDDLE", value: 3, label: "Player Middling" },
);

export const BET_DATES = new Enum(
    { key: "ALL", value: 0, label: "All" },
    { key: "TODAY", value: 1, label: "Today" },
    { key: "TOMORROW", value: 2, label: "Tomorrow" },
);

export const ODD_FORMATS = new Enum(
    { key: "US", value: 2, label: "US Odds (150)" },
    { key: "EU", value: 1, label: "EU Odds (1.50)" },
);

export const TIMEZONES = new Enum(
    { key: "EST", value: "America/New_York", label: "Eastern Standard Time" },
    { key: "CST", value: "America/Chicago", label: "Central Standard Time" },
    { key: "MST", value: "America/Denver", label: "Mountain Standard Time" },
    { key: "PST", value: "America/Los_Angeles", label: "Pacific Standard Time" },
);

export const SORT_OPTIONS = new Enum(
    { key: "PROFIT_ASC", value: "profit", label: "Profit Asc" },
    { key: "PROFIT_DESC", value: "-profit", label: "Profit Desc" },
    { key: "DATE_ASC", value: "starts", label: "Date Asc" },
    { key: "DATE_DESC", value: "-starts", label: "Date Desc" },
);

export const AUTO_REFRESH_OPTIONS = new Enum(
    { key: "DISABLED", value: -1, label: "Disabled" },
    { key: "TEN", value: 10, label: "10 Seconds" },
    { key: "THIRTY", value: 30, label: "30 Seconds" },
    { key: "SIXTY", value: 60, label: "60 Seconds" },
);

export const BILLING_TERMS = new Enum(
    { key: "ANNUALLY", value: 7, label: "Annually" },
    { key: "MONTHLY", value: 6, label: "Monthly" },
);

export const SPORT_ICONS = {
    "Basketball": "/static/icons/basketballIcon.svg",
    "American Football": "/static/icons/americanFootballIcon.svg",
    "Baseball": "/static/icons/baseballIcon.svg",
    "Ice Hockey": "/static/icons/iceHockeyIcon.svg",
}
