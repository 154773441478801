// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: "none",
          '&:hover': {
            boxShadow: 'none',
          },
          '&:disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'all',
          }
        },
        sizeSmall: {
          height: 33,
          minWidth: 130,
        },
        sizeMedium: {
          height: 43,
          minWidth: 150,
        },
        sizeLarge: {
          height: 50,
          minWidth: 170,
        },
        textSizeSmall: { ...theme.typography.p1bold },
        textSizeMedium: { ...theme.typography.p2bold },
        textSizeLarge: { ...theme.typography.p3bold },
        iconSizeSmall: {
          width: 14,
          height: 14,
          marginBottom: "5px"
        },
        iconSizeMedium: {
          width: 16,
          height: 16,
        },
        iconSizeLarge: {
          width: 18,
          height: 18,
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
          backgroundColor: theme.palette.primary[500],
          color: theme.palette.shades[0],
          '&:hover': {
            backgroundColor: theme.palette.primary[400],
          },
          '&:focus': {
            backgroundColor: theme.palette.primary[600],
          },
          '&:disabled': {
            backgroundColor: theme.palette.neutral[200],
            color: theme.palette.neutral[500],
          },
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
          backgroundColor: theme.palette.secondary[500],
          color: theme.palette.shades[0],
          '&:hover': {
            backgroundColor: theme.palette.secondary[400],
          },
          '&:focus': {
            backgroundColor: theme.palette.secondary[600],
          },
          '&:disabled': {
            backgroundColor: theme.palette.neutral[200],
            color: theme.palette.neutral[500],
          },
        },
        outlinedPrimary: {
          border: `1px solid ${theme.palette.primary[500]}`,
          color: theme.palette.primary[500],
          backgroundColor: theme.palette.shades[0],
          '&:hover': {
            border: `1px solid ${theme.palette.primary[400]}`,
            color: theme.palette.primary[400],
          },
          '&:focus': {
            border: `1px solid ${theme.palette.primary[600]}`,
            color: theme.palette.primary[600],
          },
          '&:disabled': {
            border: `1px solid ${theme.palette.neutral[200]}`,
            color: theme.palette.neutral[500],
          },
        },
        outlinedSecondary: {
          border: `1px solid ${theme.palette.secondary[500]}`,
          color: theme.palette.secondary[500],
          backgroundColor: theme.palette.shades[0],
          '&:hover': {
            border: `1px solid ${theme.palette.secondary[400]}`,
            color: theme.palette.secondary[400],
          },
          '&:focus': {
            border: `1px solid ${theme.palette.secondary[600]}`,
            color: theme.palette.secondary[600],
          },
          '&:disabled': {
            border: `1px solid ${theme.palette.neutral[200]}`,
            color: theme.palette.neutral[500],
          },
        },
      }
    }
  };
}
