import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Box, Stack, Divider, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Page from 'src/components/Page';
import OddFormatPopover from './main/OddFormatPopover';
import TimezonePopover from './main/TimezonePopover';
import { useStore } from 'src/store/Store';
import { fTimeSuffix } from 'src/utils/formatTime';


const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const StyledConfigBar = styled(Box)(({ theme }) => ({
    marginTop: "-24px",
    paddingTop: "8px",
    paddingBottom: "8px",
    backgroundColor: theme.palette.shades[0],
    [theme.breakpoints.up('lg')]: {
        marginTop: "-38px",
    }
}));

const StyledDateTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.p1,
    color: theme.palette.neutral[500],
    marginRight: 4
}));


export default function SmartbetAppLayout({ children, title, sx = {} }) {
    const location = useLocation();
    const [date, setDate] = useState(new Date());
    const [store, dispatch] = useStore();

    useEffect(() => {
        if (location.pathname !== "/surebet/profile") {
            const dateLoop = setInterval(() => setDate(new Date()), 1000);
            return () => clearInterval(dateLoop);
        }
    }, []);

    return (
        <RootStyle title={`AlwaysWin | ${title}`} sx={sx}>
            <Box sx={{ width: "100%" }}>
                {location.pathname !== "/surebet/profile" ? <StyledConfigBar >
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        divider={<Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 0.5 }} />}
                    >
                        <TimezonePopover />
                        <OddFormatPopover />
                        <StyledDateTypography>
                            {fTimeSuffix(date, store.user.timezone)}
                        </StyledDateTypography>
                    </Stack>
                </StyledConfigBar> : null}
                {children}
            </Box>
        </RootStyle>
    );
}
