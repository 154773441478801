import Hero from "src/components/landing/sections/Hero";
import About from "src/components/landing/sections/About";
import HowItWorks from "src/components/landing/sections/HowItWorks";
import SampleSurebets from "src/components/landing/sections/SampleSurebets";
import Features from "src/components/landing/sections/Features";
import EmailSubscription from "src/components/landing/sections/EmailSubscription";
import BookieSlider from "src/components/landing/sections/BookieSlider";
import FAQ from "src/components/landing/sections/FAQ";
import Pricing from "src/components/landing/sections/Pricing";
import Footer from "src/components/landing/sections/Footer";


export default function Landing() {
    return (
        <>
            <Hero />
            <About />
            <HowItWorks />
            <SampleSurebets />
            <Features />
            <EmailSubscription />
            <BookieSlider />
            <FAQ />
            <Pricing />
            <Footer />
        </>
    );
}
