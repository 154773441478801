import { useState, useEffect } from "react";
import { Typography, Stack } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { styled } from "@mui/system";
import SurebetContainer from "./surebet/SurebetContainer";
import SortPopover from "./surebet/SortPopover";
import AutoRefreshPopover from "./surebet/AutoRefreshPopover";
import BaseAppSection from "./BaseAppSection";
import BasePagination from "./BasePagination";
import BaseSkeleton from "./BaseSkeleton";
import { CommonService } from 'src/api/services';
import { APP_SUREBET_TEXT } from "src/constants/texts";
import { SORT_OPTIONS, AUTO_REFRESH_OPTIONS } from 'src/constants';
import { useStore } from 'src/store/Store';


const SurebetInfoTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.p1,
    color: theme.palette.neutral[500],
}));

const AutoRefreshTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    color: theme.palette.neutral[500],
}));


export default function AppSurebetsSection({ loading, setLoading, surebetFilters, openPricing, setOpenPricing }) {
    const [games, setGames] = useState([]);
    const [sortOption, setSortOption] = useState(SORT_OPTIONS.PROFIT_DESC.value);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [autoRefreshOption, setAutoRefreshOption] = useState(AUTO_REFRESH_OPTIONS.DISABLED.value);
    const [store, dispatch] = useStore();

    useEffect(() => {
        if (autoRefreshOption !== AUTO_REFRESH_OPTIONS.DISABLED.value) {
            const autoRefreshLoop = setInterval(() => {
                if (page !== 1) {
                    setPage(1);
                } else {
                    fetchData();
                }
            }, autoRefreshOption * 1000);
            return () => clearInterval(autoRefreshLoop);
        }
    }, [autoRefreshOption]);

    const getBetOptionLabel = (betType, fixture, home = true) => {
        if (betType && fixture) {
            if (fixture.over || fixture.under) {
                return `${fixture.over ? "over" : "under"} (${fixture.line})`;
            }
            else if (fixture.line) {
                return `${home ? "home" : "away"} (${fixture.line >= 0 ? "+" : ""}${fixture.line})`;
            } else {
                return home ? "home" : "away";
            }
        }
        return "";
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const fetchData = () => {
        setLoading(true);
        const params = { page: page, ordering: sortOption, ...surebetFilters };
        CommonService.listSurebets(params)
            .then((response) => {
                setTotalCount(response.data.count);
                const games = response.data.results.map(game => {
                    const betType = store.betTypes[game.base_bet_type];
                    return {
                        sport: game.base_sport_type,
                        country: game.country,
                        league: game.league,
                        home: game.base_first_team,
                        away: game.base_second_team,
                        profit: +game.profit.toFixed(2),
                        betType: betType,
                        betOption1: game.first_fixture ? getBetOptionLabel(betType, game.first_fixture, true) : null,
                        odd1: game.first_fixture && game.first_fixture.odd ? game.first_fixture.odd.toFixed(2) : null,
                        odd1us: game.first_fixture ? game.first_fixture.american_odd.toFixed(0) : null,
                        provider1: game.first_provider,
                        betOption2: game.second_fixture ? getBetOptionLabel(betType, game.second_fixture, false) : null,
                        odd2: game.second_fixture && game.second_fixture.odd ? game.second_fixture.odd.toFixed(2) : null,
                        odd2us: game.second_fixture ? game.second_fixture.american_odd.toFixed(0) : null,
                        provider2: game.second_provider,
                        playerName: game.full_name,
                        betStarts: new Date(game.bet_starts),
                    }
                });
                setGames(games);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (surebetFilters.provider_ids && store.betTypes) {
            if (page === 1) {
                fetchData();
            } else {
                setPage(1);
            }
        }
    }, [surebetFilters]);

    useEffect(() => {
        if (surebetFilters.provider_ids && store.betTypes) {
            fetchData();
        }
    }, [page, store.betTypes]);

    const headerIcon = "/static/icons/calculatorIcon.svg";
    const headerText = "ARBITRAGE BETTING";
    let children;
    if (loading) {
        children = [...Array(10).keys()].map(idx => <BaseSkeleton key={idx} height={164} sx={{ mb: "32px" }} />);
    } else {
        children = games.map((game, idx) => {
            return <SurebetContainer key={idx} store={store} gameData={game} openPricing={openPricing} setOpenPricing={setOpenPricing} />
        });
    };
    children.push(
        <BasePagination key={"basepagination"} count={Math.ceil(totalCount / 10)} page={page} onChange={handlePageChange} />
    );
    const extraContent = (
        <>
            <SurebetInfoTypography>{APP_SUREBET_TEXT}</SurebetInfoTypography>
            <br />
            <Stack direction="row" justifyContent="space-between" alignItems="end">
                <SortPopover sortOption={sortOption} setSortOption={setSortOption} />
                <Stack direction="row" alignItems="end">
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 0.1, sm: 0.8 }} alignItems="center" sx={{ ml: { xs: 1, sm: 0 } }}>
                        <AutoRefreshTypography>Auto Refresh</AutoRefreshTypography>
                        <AutoRefreshPopover autoRefreshOption={autoRefreshOption} setAutoRefreshOption={setAutoRefreshOption}/>
                    </Stack>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<AutorenewIcon />}
                        variant="text"
                        size="small"
                        sx={{ fontSize: "12px", p: 0 }}
                        onClick={() => fetchData()}
                    >
                        {loading ? "Loading..." : "Refresh Now"}
                    </LoadingButton>
                </Stack>
            </Stack>
            <br />
        </>
    )

    return (
        <BaseAppSection
            headerIcon={headerIcon}
            headerText={headerText}
            children={children}
            extraContent={extraContent}
        />
    );
}
