import { styled } from '@mui/system';
import { Box, Typography, Button } from '@mui/material';
import { Link } from "react-scroll";
import "src/assets/style/landing.css";
import HeroImage from "src/assets/img/landing/heroImage.png";
import HeroBackground from "src/assets/img/landing/heroBg.png";
import LogoIcon from "src/assets/svg/Logo";


const Wrapper = styled("section")(({ theme }) => ({
    paddingTop: "80px",
    width: "100%",
    minHeight: "840px",
    backgroundImage: `url(${HeroBackground})`,
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        minHeight: "440px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat, repeat",
    }
}));

const LeftSide = styled(Box)(({ theme }) => ({
    width: "50%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        order: 2,
        margin: "50px 0",
        textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
        margin: "80px 0 50px 0",
    }
}));

const RightSide = styled(Box)(({ theme }) => ({
    width: "50%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        order: 1,
        marginTop: "30px",
    }
}));

const TextHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary[600],
    fontWeight: 900,
    fontSize: "3rem",
    lineHeight: "3.2rem",
}));

const TextParagraph = styled(Box)(({ theme }) => ({
    color: theme.palette.neutral[600],
    maxWidth: "470px",
    padding: "15px 0 50px 0",
    fontSize: "1rem",
    lineHeight: "1.75rem",
    [theme.breakpoints.down("md")]: {
        padding: "15px 0 50px 0",
        textAlign: "center",
        maxWidth: "100%",
    }
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    zIndex: 9,
    marginBottom: "10rem",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        justifyContent: "center",
        marginBottom: 0,
    }
}));

const Image = styled("img")(({ theme }) => ({
    borderRadius: "1rem",
    zIndex: 9,
    [theme.breakpoints.down("sm")]: {
        width: "80%",
        height: "auto",
    }
}));


export default function Hero() {
    return (
        <Wrapper id="home" className="container flexCenterSpaced">
            <LeftSide className="flexCenter">
                <Box>
                    <LogoIcon width={240} />
                    <TextHeader>Win Either Way!</TextHeader>
                    <TextParagraph className="font13 semiBold">
                        Our arbitrage bet finding system <b>AlwaysWin</b> analyzes and calculates betting arbitrage continuously
                        so that you can focus on much return you want to get from your arbitrage bets.
                    </TextParagraph>
                    <Link style={{ cursor: "pointer" }} to="pricing" smooth={true} offset={-80}>
                        <Button color="secondary" variant="contained" size="large" sx={{ borderRadius: "8px" }}>Subscribe Now</Button>
                    </Link>
                </Box>
            </LeftSide>
            <RightSide>
                <ImageWrapper>
                    <Image src={HeroImage} />
                </ImageWrapper>
            </RightSide>
        </Wrapper>
    );
}
