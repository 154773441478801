import * as Yup from 'yup';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { styled } from '@mui/system';
import {
    Stack,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { StyledTextField } from 'src/components/auth/StyledComponents';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useStore } from 'src/store/Store';
import { ProfileService } from 'src/api/services';
import { TIMEZONES } from 'src/constants';
import { useSnackbar } from 'notistack';


const StyledNavLink = styled(NavLink)(({ theme }) => ({
    ...theme.typography.caption,
    color: theme.palette.shades[0],
    paddingLeft: "10px",
}));


export default function LoginForm() {
    const [showPassword, setShowPassword] = useState(false);
    const [store, dispatch] = useStore();
    const { enqueueSnackbar } = useSnackbar();

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            const payload = {
                username: values.email,
                password: values.password,
            };
            ProfileService.login(payload)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: "LOGIN",
                            payload: {
                                token: response.data.token,
                                user: {
                                    email: values.email,
                                    isStaff: response.data.is_staff,
                                    isSuperUser: response.data.is_superuser,
                                    oddFormat: response.data.default_odd_format,
                                    id: response.data.id,
                                    timezone: TIMEZONES.EST.value,
                                    defaultInvestAmount: response.data.default_investment_amount,
                                    subscription: response.data.active_subscription,
                                    providers: response.data.provider_preferences,
                                    trialUsed: response.data.is_trial_used_before,
                                },
                            }
                        });
                    } else { throw "login failed"; }
                })
                .catch((err) => {
                    let failMessage = "Failed to login";
                    if (err.response.data?.non_field_errors) {
                        failMessage = err.response.data.non_field_errors[0];
                    }
                    actions.setSubmitting(false);
                    enqueueSnackbar(failMessage, { variant: "error" });
                })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <StyledTextField
                            fullWidth
                            autoComplete="email"
                            type="email"
                            label="Email"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />

                        <StyledTextField
                            fullWidth
                            autoComplete="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            {...getFieldProps('password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />
                        <StyledNavLink to="/auth/reset-password">
                            Forgot your password?
                        </StyledNavLink>
                    </Stack>
                    <br />

                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                        sx={{ borderRadius: "10px" }}
                    >
                        Sign in
                    </LoadingButton>
                </Form>
            </FormikProvider >
        </>
    );
}
