import { Box, FormControlLabel, Checkbox, Divider } from "@mui/material";
import BaseAccordion from "src/components/BaseAccordion";
import { BET_TYPES } from "src/constants";


export default function BetTypeFilter({ betTypes, setBetTypes }) {
    
    const handleChangeAll = (event) => {
        setBetTypes(Array(BET_TYPES.length).fill(event.target.checked));
    }

    const handleChange = (event) => {
        let checkedState = [...betTypes];
        checkedState[event.target.dataset.idx] = event.target.checked;
        setBetTypes(checkedState);
    }

    const getCheckboxItems = () => {
        let items = [];
        [...BET_TYPES].forEach((betType, idx) => {
            items.push(
                <Box key={idx}>
                <FormControlLabel
                    label={betType.label}
                    control={
                        <Checkbox 
                            checked={betTypes[idx]}
                            inputProps={{"data-idx": idx}}
                            onChange={handleChange} />}
                />
                <Divider />
                </Box>
            );
        });
        return items;
    }

    const getChildren = () => {
        let allChecked = false;
        let allIndeterminate = false;
        const checkedStatus = [...new Set(betTypes)];
        if (checkedStatus.length === 1) {
            allChecked = checkedStatus[0];
        } else {
            allIndeterminate = true;
        }
        return (
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                    <FormControlLabel
                        label="All SmartBets"
                        control={
                            <Checkbox
                                checked={allChecked}
                                indeterminate={allIndeterminate}
                                onChange={handleChangeAll}
                            />
                        }
                    />
                    <Divider />
                    {getCheckboxItems()}
                </Box>
            </>
        );
    }

    return (
        <BaseAccordion title="BET TYPE" children={getChildren()} />
    );
}
