import { useState } from 'react';
import { Stack, Paper, Typography, Grid, Box, Button, Modal } from '@mui/material';
import { styled } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import SvgIconStyleSpan from "src/components/SvgIconStyleSpan";
import palette from 'src/theme/palette';
import { SPORT_ICONS, ODD_FORMATS } from "src/constants";
import Calculator from 'src/components/surebet/Calculator';
import { fDateTimeSuffix } from "src/utils/formatTime";


const HeaderTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.p1,
    color: theme.palette.neutral[800],
    [theme.breakpoints.down("md")]: {
        ...theme.typography.caption,
    },
}));

const TeamsTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.p2bold,
    color: theme.palette.neutral[800],
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
        whiteSpace: "normal",
        ...theme.typography.p1bold,
    },
}));

const PlayerTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.p1bold,
    color: theme.palette.neutral[700],
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
        whiteSpace: "normal",
        ...theme.typography.caption,
        fontWeight: 500,
    },
}));

const BetTypeTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.p1,
    color: theme.palette.neutral[600],
    marginTop: "4px",
    [theme.breakpoints.down("md")]: {
        ...theme.typography.caption,
    },
}));

const BetOptionTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    fontWeight: 500,
    textTransform: "uppercase",
    color: theme.palette.neutral[600],
    [theme.breakpoints.down("md")]: {
        ...theme.typography.footer,
    },
}));

const OddTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.p1,
    fontWeight: 600,
    color: theme.palette.neutral[800],
    [theme.breakpoints.down("md")]: {
        ...theme.typography.caption,
    },
}));

const ProviderTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    fontWeight: 600,
    color: theme.palette.neutral[800],
    [theme.breakpoints.down("md")]: {
        ...theme.typography.footer,
    },
}));

const ProviderContainer = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.primary[200],
    padding: theme.spacing(0.75, 1),
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0.5, 1),
    },
}));

const MoreBetsTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    color: theme.palette.primary[500],
    [theme.breakpoints.down("md")]: {
        ...theme.typography.footer,
    },
}));

const ProfitTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.p2bold,
    color: theme.palette.neutral[50],
    marginTop: theme.spacing(-0.9),
    [theme.breakpoints.down("md")]: {
        ...theme.typography.p1bold,
        marginTop: 0,
    },
}));

const CalculateTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    fontWeight: 700,
    color: theme.palette.shades[0],
    marginTop: theme.spacing(0.2),
    [theme.breakpoints.down("md")]: {
        marginTop: 0,
        fontWeight: 600,
    },
}));

const HeaderPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1, 1.8),
    borderRadius: '16px 16px 0 0',
    backgroundColor: theme.palette.primary[100],
    height: 32,
    width: "-webkit-fill-available",
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
    },
}));

const MiddlePaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: '0',
    backgroundColor: theme.palette.primary[50],
    height: 96,
    width: "-webkit-fill-available",
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
    },
}));

const ProfitPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: '0 0 0 16px',
    backgroundColor: theme.palette.primary[500],
    height: 36,
    width: "-webkit-fill-available",
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
    },
}));

const CalculatePaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: '0 0 16px 0',
    backgroundColor: theme.palette.secondary[500],
    height: 36,
    width: "-webkit-fill-available",
    maxWidth: 180,
    cursor: "pointer",
}));

const SubscribeTeaserWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary[200],
    borderRadius: '0 16px 16px 0',
    position: "relative",
    top: "-196px",
    minHeight: 164,
    maxHeight: 164,
    marginBottom: "-164px",
    width: "60%",
    marginLeft: "40%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));

const SubscribeTeaserText = styled("span")(({ theme }) => ({
    ...theme.typography.p1bold,
    fontWeight: 700,
    letterSpacing: "0.5px",
    color: theme.palette.neutral[800],
    [theme.breakpoints.down("md")]: {
        fontSize: "0.81rem",
        fontWeight: 600,
    },
}));


export default function SurebetContainer({ gameData, store, landing = false, openPricing, setOpenPricing }) {
    const [openCalculator, setOpenCalculator] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    const subscribeTeaser = (
        <SubscribeTeaserWrapper onMouseLeave={() => setIsHovering(false)}>
            <Stack justifyContent="center" alignItems="center" spacing={3}>
                <Box sx={{ maxHeight: 80 }}>
                    <SubscribeTeaserText sx={{ color: palette.secondary[500] }}>This bet has a high value. </SubscribeTeaserText>
                    <SubscribeTeaserText>If you want to see the bets with profit higher than 1%, upgrade your subscription plan.</SubscribeTeaserText>
                </Box>
                <Button
                    variant="contained"
                    color="secondary"
                    size={isSmallScreen ? "small" : "medium"}
                    onClick={() => setOpenPricing(true)}
                >Subscription
                </Button>
            </Stack>
        </SubscribeTeaserWrapper>
    );

    const getEventDetails = () => {
        if (gameData.odd1) {
            return (
                <>
                    <Grid item xs={6}>
                        <Stack justifyContent="center" alignItems="center" spacing={0.75}>
                            <BetOptionTypography>{gameData.betOption1}</BetOptionTypography>
                            <OddTypography>{store.user.oddFormat === ODD_FORMATS.US.value ? gameData.odd1us : gameData.odd1}</OddTypography>
                            <ProviderContainer>
                                <ProviderTypography>{gameData.provider1}</ProviderTypography>
                            </ProviderContainer>
                            {gameData.hasMore1 ? <MoreBetsTypography>{gameData.hasMore1}</MoreBetsTypography> : null}
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack justifyContent="center" alignItems="center" spacing={0.75}>
                            <BetOptionTypography>{gameData.betOption2}</BetOptionTypography>
                            <OddTypography>{store.user.oddFormat === ODD_FORMATS.US.value ? gameData.odd2us : gameData.odd2}</OddTypography>
                            <ProviderContainer>
                                <ProviderTypography>{gameData.provider2}</ProviderTypography>
                            </ProviderContainer>
                            {gameData.hasMore2 ? <MoreBetsTypography>{gameData.hasMore2}</MoreBetsTypography> : null}
                        </Stack>
                    </Grid>
                </>
            )
        } else {
            const bets = [0, 1].map((idx) => {
                return (
                    <Grid key={idx} item xs={6}>
                        <div onMouseOver={() => setIsHovering(true)}>
                            <Stack justifyContent="center" alignItems="center" spacing={0.75}>
                                <BetOptionTypography sx={{ filter: "blur(2px)" }}>Option</BetOptionTypography>
                                <OddTypography sx={{ filter: "blur(2px)" }}>2.00</OddTypography>
                                <ProviderContainer>
                                    <ProviderTypography sx={{ filter: "blur(2px)" }}>Bookmaker</ProviderTypography>
                                </ProviderContainer>
                            </Stack>
                        </div>
                    </Grid>
                )
            });
            return bets;
        }
    }

    return (
        <>
            <Calculator event={gameData} store={store} open={openCalculator} setOpen={setOpenCalculator} />
            <HeaderPaper>
                <Stack direction="row" justifyContent="space-between" alignItems="start">
                    <Stack direction="row">
                        <SvgIconStyleSpan src={SPORT_ICONS[gameData.sport]} sx={{ backgroundColor: palette.primary[500], color: palette.primary[500], width: "1.0rem", height: "1.0rem", mr: 1 }} />
                        <HeaderTypography noWrap>{gameData.sport} / {gameData.country} / {gameData.league}</HeaderTypography>
                    </Stack>
                    <HeaderTypography noWrap>{fDateTimeSuffix(gameData.betStarts, store.user.timezone)}</HeaderTypography>
                </Stack>
            </HeaderPaper>
            <MiddlePaper>
                <Stack direction="row" alignItems="center" spacing={0} sx={{ height: "100%" }}>
                    <Stack alignItems="flex-start" sx={{ mt: 0.5, width: "80%" }} spacing={0.5}>
                        <TeamsTypography>{gameData.home} - {gameData.away}</TeamsTypography>
                        {gameData.playerName ? <PlayerTypography>{gameData.playerName}</PlayerTypography> : null}
                        <Box sx={{ display: "inline-flex", gap: "5px" }}>
                            <BetTypeTypography>Bet Type:</BetTypeTypography>
                            {gameData.odd1 ?
                                <BetTypeTypography>{gameData.betType}</BetTypeTypography>
                                :
                                <BetTypeTypography sx={{ filter: "blur(2px)" }}>Bet Type</BetTypeTypography>
                            }
                        </Box>
                    </Stack>
                    <Grid direction="row" container spacing={1} >
                        {getEventDetails()}
                    </Grid>
                </Stack>
            </MiddlePaper>
            <Stack direction="row" sx={{ mb: 4 }}>
                <ProfitPaper>
                    <ProfitTypography noWrap>{gameData.profit}% Guaranteed Profit</ProfitTypography>
                </ProfitPaper>
                <CalculatePaper onClick={gameData.odd1 ? () => setOpenCalculator(true) : () => { }}>
                    <Stack direction="row" alignItems="center" sx={{ ml: 4 }}>
                        <SvgIconStyleSpan src="/static/icons/calculatorIcon.svg" sx={{ backgroundColor: palette.shades[0], color: palette.shades[0], width: "1.1rem", height: "1.1rem", mr: 1 }} />
                        <CalculateTypography noWrap>CALCULATE</CalculateTypography>
                    </Stack>
                </CalculatePaper>
            </Stack>
            {isHovering && !landing ? subscribeTeaser : <></>}
        </>
    );
}
