import React from "react";
import { styled } from '@mui/system';
import { Button, Box } from '@mui/material';
import { Link } from "react-scroll";
import "src/assets/style/landing.css";
import { StyledLoginButton, StyledSignupButton, StyledNavLink, ListItem } from "./StyledComponents";
import CloseIcon from "src/assets/svg/CloseIcon";
import LogoIcon from "src/assets/svg/LogoWhite";


const Wrapper = styled("nav")(({ theme }) => ({
    backgroundColor: theme.palette.primary[600],
    width: "400px",
    height: "100vh",
    position: "fixed",
    top: 0,
    padding: "0 30px",
    zIndex: 9999,
    transition: "0.5s ease",
    overflowY: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.down(400)]: {
        width: "100%",
    }
}));

const SidebarHeader = styled(Box)(({ theme }) => ({
    padding: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

const CloseButton = styled(Button)(({ theme }) => ({
    border: "0px",
    outline: "none",
    backgroundColor: "transparent",
    padding: "10px",
}));

const UlWrapper = styled("ul")(({ theme }) => ({
    padding: "40px",
    paddingTop: 0,
    "& li": {
        margin: "20px 0",
    }
}));


export default function Sidebar({ sidebarOpen, toggleSidebar }) {
    return (
        <Wrapper sx={{ right: sidebarOpen ? "0px" : "-400px" }}>
            <SidebarHeader>
                <Box className="flexCenter" sx={{ cursor: "pointer" }}>
                    <LogoIcon width={144} />
                </Box>
                <CloseButton onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
                    <CloseIcon />
                </CloseButton>
            </SidebarHeader>

            <UlWrapper className="flexCenter" style={{ flexDirection: "column", justifyContent: "center" }}>
                <ListItem>
                    <Link
                        onClick={() => toggleSidebar(!sidebarOpen)}
                        activeClass="sidebarLinkActive"
                        className="sidebarLink"
                        to="home"
                        spy={true}
                        smooth={true}
                        offset={-60}
                    >
                        Home
                    </Link>
                </ListItem>
                <ListItem>
                    <Link
                        onClick={() => toggleSidebar(!sidebarOpen)}
                        activeClass="sidebarLinkActive"
                        className="sidebarLink"
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={-60}
                    >
                        About
                    </Link>
                </ListItem>
                <ListItem>
                    <Link
                        onClick={() => toggleSidebar(!sidebarOpen)}
                        activeClass="sidebarLinkActive"
                        className="sidebarLink"
                        to="features"
                        spy={true}
                        smooth={true}
                        offset={-60}
                    >
                        Features
                    </Link>
                </ListItem>
                <ListItem>
                    <Link
                        onClick={() => toggleSidebar(!sidebarOpen)}
                        activeClass="sidebarLinkActive"
                        className="sidebarLink"
                        to="faq"
                        spy={true}
                        smooth={true}
                        offset={-60}
                    >
                        FAQ
                    </Link>
                </ListItem>
                <ListItem>
                    <Link
                        onClick={() => toggleSidebar(!sidebarOpen)}
                        activeClass="sidebarLinkActive"
                        className="sidebarLink"
                        to="pricing"
                        spy={true}
                        smooth={true}
                        offset={-60}
                    >
                        Pricing
                    </Link>
                </ListItem>
            </UlWrapper>
            <UlWrapper className="flexCenterSpaced">
                <StyledNavLink to="/auth/login">
                    <StyledLoginButton variant="contained" size="medium">
                        Login
                    </StyledLoginButton>
                </StyledNavLink>
                <StyledNavLink to="/auth/register" sx={{ ml: 2 }}>
                    <StyledSignupButton color="secondary" variant="contained" size="medium">
                        Sign up
                    </StyledSignupButton>
                </StyledNavLink>
            </UlWrapper>
        </Wrapper>
    );
}
