const urls = {
    // account
    login: "login/", // POST
    register: "signup/", // POST
    resetPassword: "password_reset/", // POST
    setResetPassword: "password_reset/confirm/", // POST
    changePassword: "change_password/", // PUT
    updateProviderPreferences: "user_provider_preferences/", // POST
    getProfileDetails: "users/", // GET
    updateProfile: (id) => `users/${id}/`, // PUT
    connectTelegram: (id) => `users/${id}/connect_telegram/`, // POST
    emailSubscribe: "users/subscription_email/", // POST
    // subscription
    listPlans: "subscription_plans/", // GET
    createSubscription: "subscriptions/", // POST
    cancelSubscription: (id) => `subscriptions/${id}/cancel/`, // POST
    createCreditCard: "user_cards/", // POST
    validatePromoCode: "subscriptions/validate_coupon/", // POST
    // common
    listBetTypes: "base_bet_types/", // GET
    listTeams: "base_teams/", // GET
    listPlayers: "base_players/", // GET
    listGames: "games/", // GET
    listProviders: "providers/", // GET
    // surebets
    listSurebets: "bet_statistics/", // GET
    listSampleSurebets: "bet_statistics/mainpage_surebets/", // GET
    // blogs
    listBlogPosts: "blog_posts/", // GET
    getBlogPostFromSlug: (slug) => `blog_posts/${slug}/`, // GET
}

export default urls;
